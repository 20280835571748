export const GetTrainStationsActions= {
	
	
	GetTrainStations_Get :{
		NEW:"GetTrainStations_Get_NEW",
		SUCCESS:"GetTrainStations_Get_SUCCESS",
		FAILED:"GetTrainStations_Get_FALIURE",
		LOADING:"GetTrainStations_Get_LOADING",
		NOT_AUTHORIZED: "GetTrainStations_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}
	,
	GetTrainStations_Post :{
		NEW:"GetTrainStations_Post_NEW",
		SUCCESS:"GetTrainStations_Post_SUCCESS",
		FAILED:"GetTrainStations_Post_FALIURE",
		LOADING:"GetTrainStations_Post_LOADING",
		NOT_AUTHORIZED: "GetTrainStations_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}
}


export const GetTrainStationsStatus ={

	GetTrainStations_Get :{
		NEW:"GetTrainStations_Get_NEW",
		SUCCESS:"GetTrainStations_Get_SUCCESS",
		FAILED:"GetTrainStations_Get_FALIURE",
		LOADING:"GetTrainStations_Get_LOADING",
		NOT_AUTHORIZED: "GetTrainStations_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	},
	GetTrainStations_Post :{
		NEW:"GetTrainStations_Post_NEW",
		SUCCESS:"GetTrainStations_Post_SUCCESS",
		FAILED:"GetTrainStations_Post_FALIURE",
		LOADING:"GetTrainStations_Post_LOADING",
		NOT_AUTHORIZED: "GetTrainStations_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}

}
