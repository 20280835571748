import {
    GetTrainStationsStatus,
    GetTrainStationsActions
  } from "./GetTrainStationsConstants";
  
  const GetTrainStations_initialState = {
    GetTrainStations_Status: GetTrainStationsStatus.GetTrainStations_Get.NEW,
    GetTrainStations_Error: "",
    TrainStationsList: []
  };
  export default function(state = GetTrainStations_initialState, action) {
    switch (action.type) {
      case GetTrainStationsActions.GetTrainStations_Get.LOADING:
        return {
          ...state,
          GetTrainStations_Status:
            GetTrainStationsStatus.GetTrainStations_Get.LOADING
        };
      case GetTrainStationsActions.GetTrainStations_Get.NEW:
        return {
          ...state,
          GetTrainStations_Status:
            GetTrainStationsStatus.GetTrainStations_Get.NEW,
      
        };
      case GetTrainStationsActions.GetTrainStations_Get.SUCCESS:
        return {
          ...state,
          GetTrainStations_Status:
            GetTrainStationsStatus.GetTrainStations_Get.SUCCESS,
          TrainStationsList: action.payload
        };
      case GetTrainStationsActions.GetTrainStations_Get.FAILED:
        return {
          ...state,
          GetTrainStations_Status:
            GetTrainStationsStatus.GetTrainStations_Get.FAILED
        };
  
      default:
        return {
          ...state,
          GetTrainStations_Error: "",
          GetTrainStations_Status: GetTrainStationsStatus.GetTrainStations_Get.NEW
        };
    }
  }
  