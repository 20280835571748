import React, { Component } from 'react'
import "./CustomerInformation.css"
import moment from 'moment';
class PointsHistory extends Component {
    constructor(props){
        super(props)
        this.state={

        }
     
    }
    componentDidMount(){
        if(this.props.setNoOfPages)
        {
            const timer = setTimeout(() => 
            {this.props.setNoOfPages(this.props.PointsCount)
               clearTimeout(timer)
            }
            , 200);
           
        }
    }
    render() {
        return (
            <>
                <table className='table-p'>
                    <thead className='heading-tab'>
                        <tr>
                            <th>Sr. #</th>
                            <th>Points Type</th>
                            <th>Issue Date</th>
                            <th>Points Issued</th>
                            <th>Expiry Date</th>
                            <th>Ticket Information</th>
                        </tr>

                    </thead>
                    <tbody className='bodyoftable'>
                        {this.props.data.length>0?this.props.data.map((res, index) => {
              return (
                        <tr
                        key={index}
                        >
                            <td>{(index+1)}</td>
                            <td>{res.points_type}</td>
                            <td>{moment(res.date_issued).format('MM/DD/YYYY')}</td>
                            <td>{res.points}</td>
                            <td>{moment(res.expiry_date).format('MM/DD/YYYY')}</td>
                            <td>{res.ticket_information?res.ticket_information.replace(/\, /g,""):'-----'}</td>
                        </tr>
                        
                            );
             }):<tr><td>No search records available</td></tr>} 
                    </tbody>
                </table>
            </>
        )
    }
}

export default PointsHistory
