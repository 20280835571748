
//******************Exchange Rate Server Calls******************;

import {AdministrationLoginActions} from './AdministrationLoginconstants';
import store from '../../Store/store'
import {ROOT_URL} from '../../Config/Config';
import {failure_messages} from 'Messages'
export const AdministrationLoginServer = {
  AdministrationLogin:AdministrationLogin,
};

//******************Insert AdministrationLogins******************;

function AdministrationLogin(data){
      const request=fetch(ROOT_URL+'/api/applicationUser/login_customer_support', {
          method: 'Post',
          mode: 'cors',
          headers: {'Content-Type':'application/json'},
          body: JSON.stringify(data)
          }).then((response)=>{
           response.json().then(response=>{
             console.log("login details: ",response)
             if(response.status == "200"){
               delete data.password
            store.dispatch({type:AdministrationLoginActions.AdministrationLogin_GET.SUCCESS,payload:{...response,...data}, message:response.message});
            return ;
            }else
            {
              store.dispatch({type:AdministrationLoginActions.AdministrationLogin_GET.FAILED, error:response.error});
              return ;
            }    
           }).catch((error) => {
            store.dispatch({type:AdministrationLoginActions.AdministrationLogin_GET.FAILED,error:failure_messages.unExpectedError});
             })
             
          }).catch((error) => {
           store.dispatch({type:AdministrationLoginActions.AdministrationLogin_GET.FAILED,error:failure_messages.unExpectedError});
            })
      return {type:AdministrationLoginActions.AdministrationLogin_GET.LOADING}

};
  
//******************Get AdministrationLogins******************;


//******************Update AdministrationLogin******************;

