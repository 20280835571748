import { PointsHistoryStatus, PointsHistoryActions } from './PointsHistoryConstants';

const PointsHistory_initialState = {
    PointsHistory_Status: PointsHistoryStatus.PointsHistory_GET.NEW,
    PointsHistory_Error: '',
    PointsHistoryDetails: [],
    PointsCount:0
}
export default  function  (state = PointsHistory_initialState, action) {
    switch (action.type) {
        case PointsHistoryActions.PointsHistory_GET.LOADING:
            return { ...state, PointsHistory_Status: PointsHistoryStatus.PointsHistory_GET.LOADING }
            case PointsHistoryActions.PointsHistory_GET.NEW:
                return { ...state, PointsHistory_Status: PointsHistoryStatus.PointsHistory_GET.NEW, PointsHistoryDetails: [], PointsCount:0 }
    
        case PointsHistoryActions.PointsHistory_GET.FAILED:
            return { ...state,  PointsHistory_Status: PointsHistoryStatus.PointsHistory_GET.FAILED, PointsHistory_Error:action.error}
        case PointsHistoryActions.PointsHistory_GET.SUCCESS:
          
          return { ...state,  PointsHistory_Status: PointsHistoryStatus.PointsHistory_GET.SUCCESS, PointsHistoryDetails:action.payload, PointsCount:action.PointsCount}
          
        
        default:
            return { ...state,
                
            }
    }
}
