import React, { Component, Fragment } from 'react';
import './AdministrationMainTwo.css';
import { Link, withRouter } from 'react-router-dom';
import { STATIC_IMAGES_URL } from '../../Config/Config';

class AdministrationMainTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }


  render() {
    return (
      <Fragment>
        <div className='header-slider'>
          <img
            src={STATIC_IMAGES_URL + 'admin_njtransit_banner.jpg'}
            alt='not found'
          />
        </div>
        <div className='adm_container-am'>
          <div className='admin-inner-container-am'>
          <div className="container">

            <div className='row'>
              <div className='col-sm-1 col-md-2 col-lg-2'></div>
              <div className=' col-sm-10 col-md-8 col-lg-8'>
                <div className='brand-icon-am'>
                  <img
                    src={STATIC_IMAGES_URL + 'portal-support-icon.svg'}
                    alt='not found'
                  />
                  <h1 className='adm_h1-am'>Customer Support</h1>
                </div>
              </div>
              <div className='col-sm-1 col-md-2 col-lg-2'></div>
            </div>

            <div className='main-section-box'>
         
              <div className='row'>
                <div className=' col-sm-1 col-md-2 col-lg-2'></div>
                <div className=' col-sm-10 col-md-8 col-lg-8'>
                  <div  id="customerSupportQuestionsdiv" className="link-customer-s">
                  <Link id="customerSupportQuestionsLink" to='QuestionsMainScreen'>
                    <div className='main-col1-am'>
                      <div className='col-box amt'>
                        <h4 className='link-heading-am'>Customer Support Questions</h4>
                        <div className='link-bo am'>
                          <div className='adm-p-link margin am'>
                            <div
                              className='adm_link1 amt'
                            >
                              Answer the questions asked by customers
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='arrow-am'>
                        <img
                          src={STATIC_IMAGES_URL + 'arrow-icon.svg'}
                          alt='not found'
                        />
                      </div>
                    </div>
                  </Link>
                  </div>
                </div>
                
                <div className=' col-sm-1 col-md-2 col-lg-2'></div>

                <div className=' col-sm-1 col-md-2 col-lg-2'></div>
                <div className=' col-sm-10 col-md-8 col-lg-8 mt-4'>
                <div  id="pointManagementdiv" className="link-customer-s">
                  <Link id="pointManagementLink" to='Generate_promotional_points'>
                    <div className='main-col1-am pm'>
                      <div className='col-box amt'>
                        <h4 className='link-heading-am'>Point Management</h4>
                        <div className='link-bo am'>
                          <div className='adm-p-link margin am'>
                            <div
                              className='adm_link1 amt'
                            >
                              Generate Promotional Points
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='arrow-am'>
                        <img
                          src={STATIC_IMAGES_URL + 'arrow-icon.svg'}
                          alt='not found'
                        />
                      </div>
                    </div>
                  </Link>
                  </div>
                </div>
                <div className=' col-sm-1 col-md-2 col-lg-2'></div>

                <div className=' col-sm-1 col-md-2 col-lg-2'></div>
                <div className=' col-sm-10 col-md-8 col-lg-8 mt-4'>
                <div  id="pointManagementdiv" className="link-customer-s">
                  <Link id="pointManagementLink" to='CustomerInformation'>
                    <div className='main-col1-am pm'>
                      <div className='col-box amt'>
                        <h4 className='link-heading-am'>Customer Information</h4>
                        <div className='link-bo am'>
                          <div className='adm-p-link margin am'>
                            <div
                              className='adm_link1 amt'
                            >
                              Points history, coupon transaction history, wallet information
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='arrow-am'>
                        <img
                          src={STATIC_IMAGES_URL + 'arrow-icon.svg'}
                          alt='not found'
                        />
                      </div>
                    </div>
                  </Link>
                  </div>
                </div>
                <div className=' col-sm-1 col-md-2 col-lg-2'></div>

                <div className=' col-sm-1 col-md-2 col-lg-2'></div>
                <div className=' col-sm-10 col-md-8 col-lg-8 mt-4'>
                <div  id="PointsTransferdiv" className="link-customer-s">
                  <Link id="PointsTransferLink" to='PointsTransfer'>
                    <div className='main-col1-am pm'>
                      <div className='col-box amt'>
                        <h4 className='link-heading-am'>Customer Points transfer</h4>
                        <div className='link-bo am'>
                          <div className='adm-p-link margin am'>
                            <div
                              className='adm_link1 amt'
                            >
                             Transfer Customer points from old email to new email
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='arrow-am'>
                        <img
                          src={STATIC_IMAGES_URL + 'arrow-icon.svg'}
                          alt='not found'
                        />
                      </div>
                    </div>
                  </Link>
                  </div>
                </div>
                <div className=' col-sm-1 col-md-2 col-lg-2'></div>

                
              </div>
            </div>


            </div>

          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(AdministrationMainTwo);
