//******************Exchange Rate Server Calls******************;

import { GetRefTransactionTypesActions } from "./GetRefTransactionTypesConstants";
import store from "../../../Store/store";
import { ROOT_URL,getCookie } from "../../../Config/Config";

export const GetRefTransactionTypesServer = {
  getGetRefTransactionTypes: getGetRefTransactionTypes
};

//******************Get GetRefTransactionTypess******************;

function getGetRefTransactionTypes() {
  const res = fetch(ROOT_URL + "/api/getRefPointsTransactionType", {
    method: "POST",
    mode: "cors",
    headers: { 
      'authorization':getCookie("admin_token_customer"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  })
    .then(res => {
      res
        .json()
        .then(response => {
          console.log("GetRefTransactionTypes:", response);

          const pointsTransactionType = [
            {value:"selectReason", name:"Select Reason",credit:""},
            ...response.data
          ]

          store.dispatch({
            type: GetRefTransactionTypesActions.GetRefTransactionTypes_Get.SUCCESS,
            payload: pointsTransactionType
          });
        })
        .catch(err => {
          console.log("GetRefTransactionTypesError:", err);
          store.dispatch({
            type: GetRefTransactionTypesActions.GetRefTransactionTypes_Get.FAILED
          });
        });
    })
    .catch(err => {
      console.log("GetRefTransactionTypesError:", err);
      store.dispatch({
        type: GetRefTransactionTypesActions.GetRefTransactionTypes_Get.FAILED
      });
    });

  return { type: GetRefTransactionTypesActions.GetRefTransactionTypes_Get.LOADING };
}
