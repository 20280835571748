import { connect } from "react-redux";
import GetRefTransactionTypes_View from "./GetRefTransactionTypes_View";
import { GetRefTransactionTypesServer } from "./server";
import { GetRefTransactionTypesActions } from "./GetRefTransactionTypesConstants";
const mapStateToProps = state => {
  console.log(state);
  return {
    GetRefTransactionTypes_Get_status: state.GetRefTransactionTypesReducer.GetRefTransactionTypes_Status,
    RefTransactionTypeList: state.GetRefTransactionTypesReducer.RefTransactionTypeList
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getGetRefTransactionTypes: () => {
      dispatch(GetRefTransactionTypesServer.getGetRefTransactionTypes());
    },
    resetReducerState:async ()=>{
      await dispatch({type:GetRefTransactionTypesActions.GetRefTransactionTypes_Get.LOADING})
       dispatch({type:GetRefTransactionTypesActions.GetRefTransactionTypes_Get.NEW})
     }
  };
};
const GetRefTransactionTypesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GetRefTransactionTypes_View);

export default GetRefTransactionTypesContainer;
