import React, { Component } from "react";
import "../GeneratePromotionalPoint/GeneratePromotionalPoint.css";
import SingleInputRadio from "../Inputs/InputRadio/SingleInputRadio";
import DatePicker from "../Inputs/DatePicker";
import SimpleInput from "../Inputs/SimpleInput";
import DateFormat from "dateformat";
import GetTrainStationsContainer from "./GetTrainStations/GetTrainStationsContainer";
import GetBusRoutesContainer from "./GetBusRoutes/GetBusRoutesContainer";
import MaterialTimePicker from "../Inputs/TimePicker/MaterialTimePicker";
import { isDate } from "moment-timezone";

import TextAreaInputComma from "../Inputs/TextAreaInputComma";
import TextAreaInput from "../Inputs/TextAreaInput";
// import LabelTextAreaInput from "../Inputs/LabelTextAreaInput";
import GetRefTransactionTypesContainer from "./GetRefTransactionTypes/GetRefTransactionTypesContainer";


class GeneratePromotionalPoint extends Component {
  constructor(props) {
    super(props);

    this.state = {
      radioEmailCheck: false,
      trainLineCheck: false,
      busRouteCheck: false,
      email: "",
      emailErrorMsg: "Field Required",
      emailFlag: false,
      TrainLine: "",
      TrainLineFlag: false,
      TrainLineErrorMsg: "Field Required",
      BusRoute: "",
      BusRouteFlag: false,
      BusRouteErrorMsg: "Field Required",
      PurchaseDate: "",
      PurchaseDateFlag: false,
      PurchaseDateErrorMsg: "Field Required",
      pointsValue: "",
      pointsValueFlag: false,
      pointsValueErrorMsg: "Field Required",
      startTime:null,
      startTimeFlag:false,
      startTimeErrMsg:"Field Required",
      endTimeFlag:false,
      endTime:null,
      endTimeErrMsg:"Field Required",
      reason:"selectReason",
      reasonFlag:false,
      reasonErrMsg:"Field Required",
      emailFileFlag:false,
      emailFileErrorMsg:"",
      emailFileURL:"",
      emailsLength:0,
      comment:null,
      commentFlag:false,
      commentErrorMsg:"",
      email_file_name:'',
      emailsFromFile:[]
    };
    
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  setStartTime = (time)=>{
    var endTime = this.state.endTime
  
    // alert(time)
    if(isDate(new Date(time)) && endTime && new Date(time) > new Date(endTime) && time)
    {

      endTime = null
    }

    this.setState({
      startTime:time,
      endTime:endTime,
      startTimeFlag:false
    })
  }

  resetEmailFileStates = ()=>{
    this.setState({
      emailFileFlag:false,
      emailFileErrorMsg:"",
      emailFileURL:null,
      emailsLength:0,
      comment:null,
      commentFlag:false,
      commentErrorMsg:"",
      email_file_name:'',
      emailsFromFile:[]
    })
  }

  setEndTime = (time)=>{
    var startTime = this.state.startTime
    if(isDate(new Date(time)) && startTime && new Date(time) < new Date(startTime) && time)
    {

      startTime = null
    }
    this.setState({
      endTime:time,
      startTime:startTime,
      endTimeFlag:false,
    })
  }

  // todo: emailRadioHandler onChange function ..........................
  emailRadioHandler = () => {
    this.setState({
      radioEmailCheck: true,
      trainLineCheck: false,
      busRouteCheck: false,
      PurchaseDateFlag:false,
      TrainLine: "",
      BusRoute: ""
    });
    console.log("radiocheck", this.state.radioEmailCheck);
  };

  // todo: trainLineRadioHandler onChange function ..........................

  busRouteRadioHandler = () => {
    this.resetEmailFileStates()

    this.setState({
      radioEmailCheck: false,
      trainLineCheck: false,
      busRouteCheck: true,
      email: "",
      emailFlag:false,
      TrainLine: ""
    });
    console.log("busRouteRadioHandler", this.state.busRouteCheck);
  };
  // todo: trainLineRadioHandler onChange function ..........................

  trainLineRadioHandler = () => {

    this.resetEmailFileStates()

    this.setState({
      radioEmailCheck: false,
      trainLineCheck: true,
      busRouteCheck: false,
      email: "",
      emailFlag:false,
      BusRoute: ""
    });
    console.log("trainLineCheck", this.state.trainLineCheck);
  };

// todo: emailHandler onChange function ..........................
emailHandler = e => {
  const emailLimit = 1000

  const reg = /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]+[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]+)[\W]*$/;
  const validReg = reg.test(e.target.value);
  if (e.target.value === ""){
    this.setState({
      emailFlag: true,
      emailErrorMsg: ""
    });
  }
  else if (validReg) {
    
    // emailsLength
    
  this.setState({ emailFlag: false });
  } else {
  this.setState({
  emailFlag: true,
  emailErrorMsg: "Enter Valid Email"
  });
  }
  var val = e.target.value;

  const emailsLength = e.target.value.split(",").length

  console.log("emails", e.target.value)
  console.log("emails", emailsLength)

  if(emailsLength > emailLimit)
  {

    this.setState({
      email:[],
      emailFlag: true,
      emailErrorMsg: "Emails entered should be less than 1000.",
      emailsLength:0
    })

  }
  else
  this.setState({ email: val , emailsLength: emailsLength});
  };
  // todo: TrainLineHandler onChange function ..........................

  TrainLineHandler = e => {
    if (this.state.TrainLine !== "" || this.state.TrainLine !== "selectType") {
      this.setState({ TrainLineFlag: false });
    }
    this.setState({ TrainLine: e.target.value });
  };

  // todo: BusRouteHandler onChange function ..........................

  BusRouteHandler = e => {
    if (this.state.BusRoute !== "" || this.state.BusRoute !== "selectType") {
      this.setState({ BusRouteFlag: false });
    }
    this.setState({ BusRoute: e.target.value });
  }
  
  reasonHandler = e => {
    if(e.target.value !== "selectReason")
      this.setState({
        reason: e.target.value,
        reasonFlag: false
      })
    else 
      this.setState({ reason: e.target.value });
  };

  commentHandler = e => {
    this.setState({ comment: e.target.value });
  };

  handleFiles = files => {
    const emailLimitFileUpload =30000

    console.log("fired.");
    let reader = new FileReader();
    let file = files.target.files[0];
    console.log("file upload data", files.target.files[0])
   if (file) {

      // const reg = /^[a-zA-Z0-9-_ ]*$/;

      let fileTypeError = file.name.endsWith('.csv') ? false : true

      // let fileNameError = reg.test(file.name);

      if (fileTypeError) {
        this.setState({
          emailFileFlag:true,
          emailErrorMsg: "only csv is allowed."
        })
      }
      else if ((file.size / (1024 * 1024)) > 1) {
        this.setState({
          emailErrorMsg: "File Size should be less than 1 MB"
        })
      }
      else {

        if (!this.isCsvFilenameValid(file)) {
          // alert("error in filename")
          this.setState({
            emailFileFlag:true,
            emailErrorMsg: "File name may includes alphabets, numbers, hyphen and underscore only.",
            emailFileURL: null
          })
          return

        }

        reader.onload = () => {
          let textData = reader.result
          let allTextLines = textData.split(/\r\n|\n/)
          // alert(allTextLines)
          let emails = []
          var error = false
          for (let entry of allTextLines) {
            var tmpEntry = ""
            // alert(entry)
            if (entry.charAt(0) == "\"") {
              for (var i = 1; i < entry.length && (entry[i - 1] + entry[i]) != "\","; i++)
                tmpEntry = tmpEntry + entry.charAt(i);
            }
            if (tmpEntry != "")
              emails.push(tmpEntry.trim())
            else if (entry.split(",")[0].trim() != "")
              emails.push(entry.split(",")[0].trim())

            // if (tmpEntry.length > 64 || entry.length > 64) {
            //   error = "Each offer code should be less than 64 characters"
            //   break;
            // }
          }

          
          if(emails.length == 1)
          error = 'File does not contain any code except header row'
          else if(emails.length < 1)
          error = 'File does not contain any codes'
          else if(emails.length > (emailLimitFileUpload-1))
          error = 'File should not have more than 30,000 emails.'

          console.log("these are emails", emails.length)
          console.log("these are the emails", emails)


          emails.shift()
          // alert(JSON.stringify(codes))
          if (error)
            this.setState({
              emailErrorMsg: error,
              emailFileFlag:true,
              emailFileURL: null
            })
            else {
              // emails = Array.from(new Set(emails))
              

            let len = emails.length
            if(len < 1)
            this.setState({
              file_error: error,
              chooseAFile: ""
            })
            if (len > this.state.maxCouponsLimit)
              len = this.state.maxCouponsLimit
            reader = new FileReader();
            reader.onload = () => {
              // this.state.chooseAFile = file.name

              console.log("reader result", reader.result)

              this.setState({
                emailsFromFile: emails,
                emailFileURL: reader.result,
                emailFlag: false,
                emailFileFlag:false,
                email_file_name: file.name
              }) 

              // alert(this.state.coupon_code_file_url)
              // this.state.file_error = ""
              // this.state.isBase64 = true
            }
            reader.readAsDataURL(file);

          }

        }
        reader.readAsText(file);
      }
    } else {

    }

  }
  //**************** handleFiles end ***********/

  //**************** isCsvFilenameValid start ***********/
  isCsvFilenameValid = (file) => {

    const reg = /^[a-zA-Z0-9-_ ]*$/;
    let filename = file.name.replace(".csv", "")

    console.log("filename", filename)

    if (reg.test(filename))
      return true

    return false
  }



  // todo: PurchaseDateHandler onChange function ..........................

  PurchaseDateHandler = e => {
    this.setState({ PurchaseDate: e, PurchaseDateFlag: false });
  };

  // todo: PurchaseDateHandler onChange function ..........................

  pointsValueHandler = e => {
    var val =e.target.value
    
    const reg = /^\d*\.?\d*$/;
    const validReg = reg.test(val);
    if (validReg) {
      this.setState({ pointsValueFlag: false });
    } else {
      this.setState({
        pointsValueFlag: true,
        pointsValueErrorMsg: "Only numeric values are allowed"
      });
    }

    const regex = /^[0]+\.?[0]*$/;

   const RegexValue = regex.test(val);
    if(RegexValue){
      this.setState({
        pointsValueFlag: true,
        pointsValueErrorMsg: "Points Value can not be zero"
      });
    }
    this.setState({ pointsValue: val });
  };

  // todo: sendButtonHandler onChange function ..........................

  sendButtonHandler = () => {
    
    var emailFlag = true,
      trainFlag = true,
      busFlag = true;

    if (this.state.trainLineCheck)
      if (this.state.TrainLineFlag || this.state.TrainLine === "") {
        trainFlag = false;
        this.setState({
          TrainLineErrorMsg:
            this.state.TrainLineFlag && this.state.TrainLine.length > 0
              ? "Only select values are allowed"
              : "Field Required",
          TrainLineFlag: true
        });
      }
    if (this.state.busRouteCheck)
      if (this.state.BusRouteFlag || this.state.BusRoute === "") {
        busFlag = false;
        this.setState({
          BusRouteErrorMsg:
            this.state.BusRouteFlag && this.state.BusRoute.length > 0
              ? "Only select values are allowed"
              : "Field Required",
          BusRouteFlag: true
        });
      }
    if (this.state.radioEmailCheck){
      // alert(this.state.emailFileFlag)

      if(this.state.emailFileFlag){

        return;
      }

      if (this.state.emailFlag || this.state.email === "" && !this.state.emailFileURL) {
        emailFlag = false;
        this.setState(
          {
            emailErrorMsg:
              this.state.emailFlag && this.state.email.length > 0
                ? "Enter Valid Email"
                : "Field Required",
            emailFlag: true
          },
          () => {
            console.log(
              "email value",
              this.state.email,
              "busDollarValueFlag",
              this.state.emailFlag
            );
          }
        );
      }




    }
    if (emailFlag && trainFlag && busFlag)
      if (this.state.PurchaseDateFlag || this.state.PurchaseDate === "" && !this.state.radioEmailCheck) {
        this.setState({
          PurchaseDateErrorMsg:
            this.state.PurchaseDateFlag && this.state.PurchaseDate.length > 0
              ? "Please select valid date formate.."
              : "Field Required",
          PurchaseDateFlag: true
        });
      }

      else if((!this.state.startTime || this.state.startTime == "Invalid Date") && this.state.PurchaseDate !== "" )
      {
        if(this.state.startTime != "Invalid Date")
        this.setState({
          startTimeFlag:true,
          endTimeFlag:false
        })
      }else if ((!this.state.endTime || this.state.endTime == "Invalid Date") && this.state.PurchaseDate !== "")
    {

      if(this.state.endTime != "Invalid Date")
      this.setState({
        endTimeFlag:true,
        startTimeFlag:false
      })
    }  
       else if (this.state.pointsValueFlag || this.state.pointsValue === "") {
        this.setState({
          pointsValueErrorMsg:
            this.state.pointsValueFlag && this.state.pointsValue.length > 0
              ? this.state.pointsValueErrorMsg
              : "Field Required",
          pointsValueFlag: true
        });
        
      }
      else if (this.state.reason === "selectReason") {
        this.setState({
          reasonFlag:true
        });        
      }
      else {
        var startTime = this.state.startTime
        var endTime = this.state.endTime
        if(isDate(startTime) && isDate(endTime))
        {
          startTime=  startTime.getHours()+":"+startTime.getMinutes()
          endTime =  endTime.getHours()+":"+endTime.getMinutes()
        }

        const { cookies } = this.props; 
        //console.log("cookies in generate promotional", JSON.parse(cookies.njt_admin_userId_customer).userName)
        let emails = []

        if(this.state.emailsFromFile.length > 0)
          emails= this.state.emailsFromFile.toString()
        else
          emails=this.state.email

        console.log("these are emails", emails)

        var data = {
          EmailCheck: this.state.radioEmailCheck,
          BusRouteCheck: this.state.busRouteCheck,
          TrainLineCheck: this.state.trainLineCheck,
          email: emails,
          BusRoute: this.state.BusRoute,
          TrainLine: this.state.TrainLine,
          PurchaseDate: this.state.PurchaseDate === ""?this.state.PurchaseDate: DateFormat(this.state.PurchaseDate, "yyyy-mm-dd"),
          pointsValue: this.state.pointsValue,
          startTime:startTime,
          endTime:endTime,
          email_file_name: this.state.email_file_name,
          comment: this.state.comment === "" ?  null: this.state.comment,
          email_file_url: this.state.emailFileURL,
          ref_points_transaction_type_id: this.state.reason,
          user_name: cookies.get("njt_admin_userId_customer") &&
          cookies.get("njt_admin_userId_customer").user_name
            ? cookies.get("njt_admin_userId_customer").user_name
            : "admin"
        };

        // console.log("this is the data", data)
        // return;
// alert(this.state.reason)
        this.props.CreatePromotionalPoints(data);
        return true;
      }
  };

  render() {
    const {
      email,
      emailFlag,
      emailErrorMsg,
      TrainLine,
      TrainLineFlag,
      TrainLineErrorMsg,
      BusRoute,
      BusRouteFlag,
      BusRouteErrorMsg,
      PurchaseDate,
      PurchaseDateFlag,
      PurchaseDateErrorMsg,
      pointsValue,
      pointsValueFlag,
      pointsValueErrorMsg,
      reason,
      reasonFlag,
      reasonErrMsg,
      emailsLength,
      emailFileURL,
      emailFileErrorMsg,
      emailFileFlag,
      comment,
      commentFlag,
      commentErrorMsg
    } = this.state;
    return (
      <div className="gpp-box">
        <div className="container">
          <div className="row">
            {/* First Row Start */}
            <div className="col-sm-2 col-md-2 col-lg-3"></div>
            <div className="col-sm-8 col-md-8 col-lg-6">
              <div className="erpc_header">
                <h1>Generate Promotional Points</h1>
              </div>
            </div>
            <div className="col-sm-2 col-md-2 col-lg-3"></div>



<div className="col-sm-2 col-md-2 col-lg-4"></div>

<div className="col-sm-8 col-md-8 col-lg-4">
<div className="gpp-box-in">
<div className="gpp-radio-btn display">
                <SingleInputRadio
                  label="Email(s)"
                  id="emailRadio"
                  onChange={this.emailRadioHandler}
                  checked={this.state.radioEmailCheck}
                /> 

                  {
                    this.state.radioEmailCheck &&
                    <div className="gpp-send-btn">
                      <input
                        type="file"
                        id="myfile"
                        name="myfile"
                        onClick={(event) => {
                          event.target.value = null
                        }}
                        disabled={this.state.email.length > 0}
                        onChange={this.handleFiles} />
                    </div>
                  }

                </div>

              <div
                className="gpp-input-text"
                style={{
                  display: this.state.radioEmailCheck ? "block" : "none"
                }}
              >
             
              <div className="box-text">
                <TextAreaInputComma
                  name="email"
                  id="emailInput"
                  inputValue={email}
                  placeholder="Enter Email(s)"
                  flag={emailFlag || emailFileFlag}
                  errorMsg={emailErrorMsg}
                  onChange={this.emailHandler}
                  maxLength={1000}
                  length={emailsLength}
                  showLength = {true}
                  disabled={this.state.emailsFromFile.length > 0}
                />
              </div>

              </div>
</div>
</div>
<div className="col-sm-2 col-md-2 col-lg-4">

</div>


<div className="col-sm-2 col-md-2 col-lg-4"></div>

<div className="col-sm-8 col-md-8 col-lg-4">
<div className="gpp-box-in">
<div className="gpp-radio-btn">
                <SingleInputRadio
                  label="Train Station"
                  id="trainStaionRadio"
                  onChange={this.trainLineRadioHandler}
                  checked={this.state.trainLineCheck}
                />
              </div>
              <div
                className="gpp-input-text"
                style={{
                  display: this.state.trainLineCheck ? "block" : "none"
                }}
              >
                <GetTrainStationsContainer
                  inputValue={TrainLine}
                  flag={TrainLineFlag}
                  errorMsg={TrainLineErrorMsg}
                  onChange={this.TrainLineHandler}
                />
              </div>
</div>
</div>
<div className="col-sm-2 col-md-2 col-lg-4">

</div>



<div className="col-sm-2 col-md-2 col-lg-4"></div>

<div className="col-sm-8 col-md-8 col-lg-4">
<div className="gpp-box-in">
<div className="gpp-radio-btn">
                <SingleInputRadio
                  label="Bus Route"
                  id="busRouteRadio"
                  onChange={this.busRouteRadioHandler}
                  checked={this.state.busRouteCheck}
                />
              </div>
              <div
                className="gpp-input-text"
                style={{ display: this.state.busRouteCheck ? "block" : "none" }}
              >
                <GetBusRoutesContainer
                  type="text"
                  name="BusRoute"
                  inputValue={BusRoute}
                  flag={BusRouteFlag}
                  errorMsg={BusRouteErrorMsg}
                  onChange={this.BusRouteHandler}
                />
              </div>
</div>
</div>
<div className="col-sm-2 col-md-2 col-lg-4">

</div>


<div className="col-sm-2 col-md-2 col-lg-4"></div>

<div className="col-sm-8 col-md-8 col-lg-4">
<div className="gpp-box-in">
<div className="gpp-left-input">
                <label className="gpp-date-label">Ticket Purchase Date</label>
                <div className="gpp-date-pic">
                  <DatePicker
                    htmlFor="custom-date"
                    inputValue={PurchaseDate}
                    flag={PurchaseDateFlag}
                    errorMsg={PurchaseDateErrorMsg}
                    onChange={this.PurchaseDateHandler}
                  />
                </div>
              </div>
</div>
</div>
<div className="col-sm-2 col-md-2 col-lg-4">

</div>


<div className="col-sm-2 col-md-2 col-lg-4"></div>

<div className="col-sm-8 col-md-8 col-lg-4">
<div className="gpp-box-in">
<div className="time-inn">
              <div className="time-left">
              <label className="gpp-date-label point">Start Time</label>
              <div className="time-box">
                <MaterialTimePicker disabled={this.state.PurchaseDate === ""}  inputValue={this.state.startTime} onChange={this.setStartTime}/> 
              {this.state.startTimeFlag?<p className="error-message-time">Field Required</p>:null}
            </div>
            </div>
            <div className="time-left margin">
              <label className="gpp-date-label point">End Time</label>
              <div className="time-box">
              <MaterialTimePicker disabled={this.state.PurchaseDate === ""} inputValue={this.state.endTime} onChange={this.setEndTime}/> 
              {this.state.endTimeFlag?<p className="error-message-time">Field Required</p>:null}
            </div>
            </div>
            </div>
</div>
</div>
<div className="col-sm-2 col-md-2 col-lg-4">

</div>


<div className="col-sm-2 col-md-2 col-lg-4"></div>

<div className="col-sm-8 col-md-8 col-lg-4">
<div className="gpp-box-in">
<div className="gpp-right-side">
                <label className="gpp-date-label point">Points</label>
                <div className="gpp-point-b">
                  <SimpleInput
                    type="text"
                    name="point"
                    id="pointBtn"
                    inputValue={pointsValue}
                    flag={pointsValueFlag}
                    errorMsg={pointsValueErrorMsg}
                    onChange={this.pointsValueHandler}
                    placeholder="Enter Promotional Points Here"
                  />
                </div>
              </div>
</div>
</div>
<div className="col-sm-2 col-md-2 col-lg-4">

</div>


<div className="col-sm-2 col-md-2 col-lg-4"></div>

            <div className="col-sm-8 col-md-8 col-lg-4">

              <label className="gpp-date-label point">Reason</label>
              <div className="gpp-point-b">
                <GetRefTransactionTypesContainer
                  type="text"
                  name="Reason"
                  inputValue={reason}
                  flag={reasonFlag}
                  errorMsg={reason}
                  onChange={this.reasonHandler}
                />

              </div>
            </div>
<div className="col-sm-2 col-md-2 col-lg-4"></div>


<div className="col-sm-2 col-md-2 col-lg-4"></div>

<div className="col-sm-8 col-md-8 col-lg-4">
<div className="gpp-box-in">
<div className="gpp-right-side">




<label className="gpp-date-label point">Comment (optional)</label>
            <div className="box-text-comment">
              <TextAreaInput
                name="comment"
                id="commentInput"
                inputValue={comment}
                placeholder="Enter Comments..."
                flag={commentFlag}
                errorMsg={commentErrorMsg}
                onChange={this.commentHandler}
                maxLength="300"
                showLength={true}
              />
            </div>

</div>
</div>


</div>
            

            <div className="col-sm-2 col-md-2 col-lg-4"></div>


<div className="col-sm-2 col-md-2 col-lg-4"></div>

<div className="col-sm-8 col-md-8 col-lg-4">
<div className="gpp-box-in">
<div className="gpp-box-in margin2">
              <div className="gpp-send-btn">
                <button id="SendPoint" className="gpp-button" onClick={this.sendButtonHandler}>
                  Send Points
                </button>
              </div>
            </div>
</div>
</div>
<div className="col-sm-2 col-md-2 col-lg-4">

</div>



          </div>

        </div>
        <div className="gpp-container">
    
          <div className="gpp-in-container">

            <div className="gpp-box-in">
              
            </div>


            <div className="gpp-box-in">
            
            </div>
            <div className="gpp-box-in">
              
            </div>
            <div className="gpp-box-in">
             

            
            </div>
            
          </div>
        </div>
      </div>
    );
  }
}

export default GeneratePromotionalPoint;
