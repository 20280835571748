import React, { Component } from 'react'
import "./PasswordShowHide.css"

class PasswordShowHide extends Component {
    state = {
        type: 'password',
      }

      handleClick = () => this.setState(({type}) => ({
        type: type === 'text' ? 'password' : 'text'
      }))
    render() {
        const { id="password",label = 'label', type = "text", inputValue, placeholder, name = "name", onChange, errorMsg = 'errorMsg', flag = false, containerStyle, readOnly = false, maxLength, disabled, inputStyle, star } = this.props
        
        return (
            <div className="li_container tex-pass" style={containerStyle}>
            <label htmlFor={id} className="li_label">{label}<span style={{color: "red"}}>{star}</span></label>
                <div className="li_input_container">
                    <input id={id} type={this.state.type} className="li_input padding" style={inputStyle} placeholder={placeholder} name={name} value={inputValue} onChange={onChange} readOnly={readOnly} maxLength={maxLength} disabled={disabled} />
                    <span className="material-icons mat-pass" onClick={this.handleClick}>{this.state.type === 'text' ? 'visibility_off' : 'visibility'}</span>
                    <p className="li_error_text" style={{ display: flag ? 'block' : 'none' }}>{errorMsg}</p>
                </div>
            </div>
        )
    }
}

export default PasswordShowHide
