//****************** Server Config  ******************;
import preval from 'preval.macro'


export  const ROOT_URL = preval`module.exports =process.env.ADMIN_API_URL` ///53cr3tk#y

export  const VERSION_NO =  preval`module.exports =process.env.ADMIN_CUSTOMER_SUPPORT_WEB_VERSION_NO` 

export function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

/* ************************************************* */
/* ************************************************* */
/* ********** Images Url Dynamic Const export S3 sever************* */
/* ************************************************* */
/* ************************************************* */

export const STATIC_IMAGES_URL = preval`module.exports =process.env.STATIC_IMAGES_URL` 
export const ADMIN_END_BRANDING_IMAGE_URL = preval`module.exports =process.env.ADMIN_END_BRANDING_IMAGE_URL` 