import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  // DatePicker,
  // TimePicker,
  // DateTimePicker,
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from '@material-ui/pickers';
import './TimePicker.css'
import Icon from "@material-ui/core/Icon";

function MaterialTimePicker(props) {
  const [selectedDate, handleDateChange] = useState(null);
  const {
    inputValue = selectedDate,
    onChange = handleDateChange,
    // errorMsg,
    // flag,
    // onClick,
    disabled =false,
    placeholder="hh:mm"
  } = props;
  // alert(inputValue)
  // alert(JSON.stringify(props))
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
     
        <KeyboardTimePicker
        clearable
        ampm={false}
        value={inputValue}
        onChange={onChange}
        label=""
        placeholder={placeholder}
        disabled={disabled}
        // onInvalid={()=>alert("test")}
        // helperText={null}
        mask={"__:__"}
        KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          keyboardIcon={<Icon>schedule</Icon>}
      />
      
    </MuiPickersUtilsProvider>
  );
}

export default MaterialTimePicker;