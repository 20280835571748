export const GeneratePromotionalPointActions= {
	
	
	GeneratePromotionalPoint_Get :{
		NEW:"GeneratePromotionalPoint_Get_NEW",
		SUCCESS:"GeneratePromotionalPoint_Get_SUCCESS",
		FAILED:"GeneratePromotionalPoint_Get_FALIURE",
		LOADING:"GeneratePromotionalPoint_Get_LOADING",
		NOT_AUTHORIZED: "GeneratePromotionalPoint_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	},
	GeneratePromotionalPoint_Post :{
		NEW:"GeneratePromotionalPoint_Post_NEW",
		SUCCESS:"GeneratePromotionalPoint_Post_SUCCESS",
		FAILED:"GeneratePromotionalPoint_Post_FALIURE",
		LOADING:"GeneratePromotionalPoint_Post_LOADING",
		NOT_AUTHORIZED: "GeneratePromotionalPoint_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}
}


export const GeneratePromotionalPointStatus ={

	GeneratePromotionalPoint_Get :{
		NEW:"GeneratePromotionalPoint_Get_NEW",
		SUCCESS:"GeneratePromotionalPoint_Get_SUCCESS",
		FAILED:"GeneratePromotionalPoint_Get_FALIURE",
		LOADING:"GeneratePromotionalPoint_Get_LOADING",
		NOT_AUTHORIZED: "GeneratePromotionalPoint_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	},
	GeneratePromotionalPoint_Post :{
		NEW:"GeneratePromotionalPoint_Post_NEW",
		SUCCESS:"GeneratePromotionalPoint_Post_SUCCESS",
		FAILED:"GeneratePromotionalPoint_Post_FALIURE",
		LOADING:"GeneratePromotionalPoint_Post_LOADING",
		NOT_AUTHORIZED: "GeneratePromotionalPoint_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}

}
