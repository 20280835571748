import { connect } from "react-redux";
import AdministrationLogoutView from "./AdministrationLogout_View";
import {AdministrationLogoutServer} from "./server"
const mapStateToProps = state => {
    console.log(state)
    return {
        AdministrationLogout_status:state.AdministrationLogoutReducer.AdministrationLogout_Status,
        AdministrationLogout_Error:state.AdministrationLogoutReducer.AdministrationLogout_Error,

    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        AdministrationLogout: (data, token) => {

           dispatch( AdministrationLogoutServer.AdministrationLogout(data, token) )
        },
 
    }
};
const AdministrationLogoutContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AdministrationLogoutView)

export default AdministrationLogoutContainer;