import React, { Component, Fragment } from "react";
import { GetTrainStationsStatus } from "./GetTrainStationsConstants";
import GetTrainStations from "./GetTrainStations";

import Snackbar from "@material-ui/core/Snackbar/";
import Loading from "../../Utils/Loading";

export class GetTrainStationsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      mount: false,
      inputValue:""
    };
    props.getGetTrainStations();
  }
  closeSnackBar = () => {
    this.setState({
      open: false
    });
  };

  static getDerivedStateFromProps(props, state) {
    if (JSON.stringify(props.inputValue) !== JSON.stringify(state.inputValue)) {
   
      if(props.inputValue == "")
      props.resetReducerState()
        return {
          inputValue: props.inputValue
        };
 
    } else
     return null;
  }
  getScreen(status) {
    // alert(status)
    switch (status) {
      case GetTrainStationsStatus.GetTrainStations_Get.NEW:
        //  alert(JSON.stringify( this.props.GetTrainStations))
        return (
          <GetTrainStations
          TrainStationsList={this.props.TrainStationsList}
            inputValue={this.props.inputValue}
            flag={this.props.flag}
            onChange={this.props.onChange}
          />
        );

      case GetTrainStationsStatus.GetTrainStations_Get.SUCCESS:
       // alert(JSON.stringify(this.props.TrainStationsList))
        return (
          <GetTrainStations
            TrainStationsList={this.props.TrainStationsList}
            inputValue={this.props.inputValue}
            flag={this.props.flag}
            onChange={this.props.onChange}
          />
        );
      case GetTrainStationsStatus.GetTrainStations_Get.FAILED:
         alert(JSON.stringify( this.props.GetTrainStations))
        return (
          <Fragment>
            <Snackbar
              open={this.state.open}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
              ContentProps={{
                "aria-describedby": "message-id"
              }}
              message={
                <span id="message-id">Unable to get list of Stations</span>
              }
            />
            <GetTrainStations
              TrainStationsList={[]}
              inputValue={this.props.inputValue}
              flag={this.props.flag}
              onChange={this.props.onChange}
            />
          </Fragment>
        );

      case GetTrainStationsStatus.GetTrainStations_Get.LOADING:
        this.state.open = true;
        return (
          <div style={{ textAlign: "center" }}>
           <Loading/>
          </div>
        );
      default:
        return <div />;
    }
  }
  render() {
    return this.getScreen(this.props.GetTrainStations_Get_status);
  }
}

export default GetTrainStationsView
