import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import "../AdministrationMain/AdministrationMain.css";

const modalRoot = document.getElementById("message_alert_logout");


export class Modal extends React.Component {
  componentDidMount(){
    document.body.style.overflow = "hidden"
  }
  componentWillUnmount(){
    document.body.style.overflow = "auto"
  }
    render() {
      const {
        onConfirm,
        message = "Are you sure you want to sign out?",
        onClose
      } = this.props
      return ReactDOM.createPortal(
        <div
          style={{
            position: 'fixed',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
            display: 'grid',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.3)',
            minHeight: "100vh",
            zIndex:"9999999999"
          }}
        >
          <div
            style={{
              padding: 20,
              background: '#fff',
              borderRadius: '2px',
              display: 'inline-block',
              minHeight: '200px',
              margin: '1rem',
              position: 'relative',
              minWidth: '300px',
              boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
              justifySelf: 'center',
            }}
          >
            {this.props.children}
            <div className="main-del-box">
                <div className="del-pop-text">
                {message}
                </div>
            </div>
            
            <div className="del-pop-bnt">
                <button id="yesLink" onClick={onConfirm} className="btn-yes">Yes</button>
                <button id="noLink" onClick={onClose} className="btn-clos">No</button>
            </div>
          
          </div>
        </div>,
        modalRoot,
      )
    }
  }


class AdministrationLogoutPopup extends Component {
    state = {showModal: false}
  handleShowMessageClick = () => this.setState({showModal: true})
  handleCloseModal = () => this.setState({showModal: false})
  render() {
    return (
      <div
        style={{
          height: '100%',
          display: 'grid',
          justifyContent: 'right',
          alignItems: 'right',
        }}
      >
        
        
          <button onClick={this.handleShowMessageClick} className="inner-header-btn">
                Sign Out
          </button>
          {this.state.showModal ? (
            <Modal onClose={this.handleCloseModal}>
            </Modal>
          ) : null}
      </div>

    )
  }
}

export default AdministrationLogoutPopup
