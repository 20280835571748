import { connect } from "react-redux";
import TransactionHistoryView from "./TransactionHistory_View";
import {TransactionHistoryServer} from "./server"
import {TransactionHistoryActions} from './TransactionHistoryConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        TransactionHistory_GET_status:state.TransactionHistoryReducer.TransactionHistory_Status,
        TransactionHistory:state.TransactionHistoryReducer.TransactionHistoryDetails,
        TransactionHistory_Error:state.TransactionHistoryReducer.TransactionHistory_Error,
        TransactionsCount:state.TransactionHistoryReducer.TransactionsCount
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        getRedeemedCouponsByUserId: (data) => {

           dispatch( TransactionHistoryServer.getRedeemedCouponsByUserId(data) )
        },
    
        resetReducerState:()=>{
            dispatch({type:TransactionHistoryActions.TransactionHistory_GET.NEW})
        }
    }
};
const TransactionHistoryContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TransactionHistoryView)

export default TransactionHistoryContainer;