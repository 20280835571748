export const RiderQueationsListActions= {
	
	RiderQueationsList_GET :{
		NEW:"RiderQueationsList_GET_NEW",
		SUCCESS:"RiderQueationsList_GET_SUCCESS",
		FAILED:"RiderQueationsList_GET_FALIURE",
		LOADING:"RiderQueationsList_GET_LOADING",
		UPDATE:"DisableBusiness_Update_SUCCESS",
	},
	
}


export const RiderQueationsListStatus ={

	RiderQueationsList_GET :{
		NEW:"RiderQueationsList_GET_NEW",
		SUCCESS:"RiderQueationsList_GET_SUCCESS",
		FAILED:"RiderQueationsList_GET_FALIURE",
		LOADING:"RiderQueationsList_GET_LOADING",
	}

}
