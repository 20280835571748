import { connect } from "react-redux";
import GeneratePromotionalPoint_View from "./GeneratePromotionalPoint_View";
import { GeneratePromotionalPointServer } from "./server";
const mapStateToProps = state => {
  console.log(state);
  return {
    GeneratePromotionalPoint_Get_status:
      state.GeneratePromotionalPointReducer.GeneratePromotionalPoint_Status,
      PromotionalPoint_Message:
      state.GeneratePromotionalPointReducer.GeneratePromotionalPoint_Message,
    GeneratePromotionalPoint:
      state.GeneratePromotionalPointReducer.GeneratePromotionalPointDetails
  };
};
const mapDispatchToProps = dispatch => {
  return {
    CreatePromotionalPoints: (data) => {
      dispatch(GeneratePromotionalPointServer.CreatePromotionalPoints(data));
    }
  };
};
const GeneratePromotionalPointContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneratePromotionalPoint_View);

export default GeneratePromotionalPointContainer;
