import React, { Suspense, Component } from 'react';
import { Route } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import './App.css';

import { VERSION_NO } from './Config/Config';
import AdministrationMain from './Components/AdministrationMain/AdministrationMain';
import AdministrationLoginPage from './Components/AdministrationLoginPage/AdministrationLoginContainer';
import Header from './Components/HeaderSection/Header';
import GeneratePromotionalPoint from './Components/GeneratePromotionalPoint/GeneratePromotionalPointContainer';
import QuestionsMainScreen from './Components/RiderSupportQuestions/QuestionsMainScreen';
import AdministrationMainTwo from './Components/AdministrationMain/AdministrationMainTwo';
import HeaderInner from "./Components/HeaderSection/HeaderBranding"
import InnerHeader from './Components/HeaderSection/InnerHeader';
import InnerHeaderBranding from "./Components/HeaderSection/InnerHeaderBranding"
import CustomerInformation from "./Components/CustomerInformation/CustomerInformation"
import PointsTransfer from "./Components/PointsTransfer/PointsTransfer"



class App extends Component {
  componentWillMount() {}

  render() {
  
    return (
      <div>
           {/* <Header cookies={this.props.cookies} /> */}
           <HeaderInner cookies={this.props.cookies} />
        {/* <InnerHeader cookies={this.props.cookies} /> */}
        <InnerHeaderBranding cookies={this.props.cookies} />

        <Suspense fallback={<div style={{ height: '100vh' }}> Loading...</div>}>
       
       

          <Route
            exact
            path='/'
            render={() => (
              <AdministrationLoginPage cookies={this.props.cookies} />
            )}
          />
          <Route
            exact
            path='/Admin'
            // render={() => <AdministrationMain cookies={this.props.cookies} />}
            render={() => <AdministrationMainTwo cookies={this.props.cookies} />}
          />
          <Route
            exact
            path='/Generate_promotional_points'
            render={() => (
              <GeneratePromotionalPoint cookies={this.props.cookies} />
            )}
          />
          <Route
            exact
            path='/QuestionsMainScreen'
            render={() => <QuestionsMainScreen cookies={this.props.cookies} />}
          />
           <Route
            exact
            path='/CustomerInformation'
            render={() => <CustomerInformation cookies={this.props.cookies} />}
          />
          <Route
            exact
            path='/PointsTransfer'
            render={() => <PointsTransfer cookies={this.props.cookies} />}
          />
        </Suspense>
        <div className='version-get-b'>
          <div className='version'>{VERSION_NO}</div>
        </div>
      </div>
    );
  }
}

export default withCookies(App);
