export const OfferInWallerActions= {
	
	OfferInWaller_GET :{
		NEW:"OfferInWaller_GET_NEW",
		SUCCESS:"OfferInWaller_GET_SUCCESS",
		FAILED:"OfferInWaller_GET_FALIURE",
		LOADING:"OfferInWaller_GET_LOADING",
		UPDATE:"DisableBusiness_Update_SUCCESS",
	},
	
}


export const OfferInWallerStatus ={

	OfferInWaller_GET :{
		NEW:"OfferInWaller_GET_NEW",
		SUCCESS:"OfferInWaller_GET_SUCCESS",
		FAILED:"OfferInWaller_GET_FALIURE",
		LOADING:"OfferInWaller_GET_LOADING",
	}

}
