export const SendAnswerActions= {
	
	SendAnswer_POST :{
		NEW:"SendAnswer_POST_NEW",
		SUCCESS:"SendAnswer_POST_SUCCESS",
		FAILED:"SendAnswer_POST_FALIURE",
		LOADING:"SendAnswer_POST_LOADING",
	},
	
}


export const SendAnswerStatus ={

	SendAnswer_POST :{
		NEW:"SendAnswer_POST_NEW",
		SUCCESS:"SendAnswer_POST_SUCCESS",
		FAILED:"SendAnswer_POST_FALIURE",
		LOADING:"SendAnswer_POST_LOADING",
	}

}
