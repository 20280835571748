
//******************Exchange Rate Server Calls******************;

import {UserUnsedPointsActions} from './UserUnsedPointsConstants';
import store from '../../../Store/store'
import {ROOT_URL,getCookie} from '../../../Config/Config';
import {failure_messages} from "Messages"


export const UserUnsedPointsServer = {
  getUserUnsedPointsByUserId:getUserUnsedPointsByUserId,
  getRiderAnsweredQuestions:getRiderAnsweredQuestions
};

//******************Insert UserUnsedPointss******************;

function getUserUnsedPointsByUserId(data){
  // data.props.setLoading(true)
      const request=fetch(ROOT_URL+'/api/customer/getUnusedPointsByUserId', {
          method: 'Post',
          mode: 'cors',
          headers: { 
            'authorization':getCookie("admin_token_customer"),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
          }).then((response)=>{
           response.json().then(response=>{
             if(response.status == "200"){
              // data.props.setLoading(false)
          // alert(response.count)
              // if(data.props.setQuestionList && data.props.activePage == 1){
              //   data.props.setQuestionList(response.QuestionsCount)
              // }
            store.dispatch({type:UserUnsedPointsActions.UserUnsedPoints_GET.SUCCESS, PointsCount:response.CumPoints})// , QuestionsCount:response.QuestionsCount});
            return ;
            }else
            {
              // data.props.setLoading(false)
              store.dispatch({type:UserUnsedPointsActions.UserUnsedPoints_GET.FAILED, error:response.error});
              return ;
              }    
           });
          }).catch((error) => {
            // if(data.props.setQuestionList){
            //   data.props.setQuestionList(0)
            // }
            // data.props.setLoading(false)
           store.dispatch({type:UserUnsedPointsActions.UserUnsedPoints_GET.FAILED, error:failure_messages.unExpectedError});
            })
      return {type:UserUnsedPointsActions.UserUnsedPoints_GET.LOADING}

};
  

function getRiderAnsweredQuestions(data){
  // alert(JSON.stringify(data))
  const request=fetch(ROOT_URL+'/api/support/getRiderAnsweredQuestions', {
      method: 'Post',
      mode: 'cors',
      headers: { 
        'authorization':getCookie("admin_token_customer"),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
      }).then((response)=>{
       response.json().then(response=>{
         if(response.status == "200"){
         // alert(JSON.stringify(response))
        store.dispatch({type:UserUnsedPointsActions.UserUnsedPoints_GET.SUCCESS,payload:response.data, QuestionsCount:response.QuestionsCount});
        return ;
        }else
        {
          store.dispatch({type:UserUnsedPointsActions.UserUnsedPoints_GET.FAILED, error:response.error});
          return ;
          }    
       });
      }).catch((error) => {
      //  alert(error)
       store.dispatch({type:UserUnsedPointsActions.UserUnsedPoints_GET.FAILED, error:failure_messages.Businessparticipants});
        })
  return {type:UserUnsedPointsActions.UserUnsedPoints_GET.LOADING}

};
