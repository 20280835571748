//******************Exchange Rate Server Calls******************;

import { GetBusRoutesActions } from "./GetBusRoutesConstants";
import store from "../../../Store/store";
import { ROOT_URL,getCookie } from "../../../Config/Config";

export const GetBusRoutesServer = {
  getGetBusRoutes: getGetBusRoutes
};

//******************Get GetBusRoutess******************;

function getGetBusRoutes() {
  const res = fetch(ROOT_URL + "/api/userPoints/getBusRoutes", {
    method: "POST",
    mode: "cors",
    headers: { 
      'authorization':getCookie("admin_token_customer"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  })
    .then(res => {
      res
        .json()
        .then(response => {
          console.log("GetBusRoutes:", response);
          store.dispatch({
            type: GetBusRoutesActions.GetBusRoutes_Get.SUCCESS,
            payload: response.data
          });
        })
        .catch(err => {
          console.log("GetBusRoutesError:", err);
          store.dispatch({
            type: GetBusRoutesActions.GetBusRoutes_Get.FAILED
          });
        });
    })
    .catch(err => {
      console.log("GetBusRoutesError:", err);
      store.dispatch({
        type: GetBusRoutesActions.GetBusRoutes_Get.FAILED
      });
    });

  return { type: GetBusRoutesActions.GetBusRoutes_Get.LOADING };
}
