import React, { Component } from "react";
import UploadBannerHeader from "../../Components/UploadBanner/UploadBannerHeader/UploadBannerHeader";
import "../RiderSupportQuestions/RiderSupportQuestions.css";
import DatePicker from "../Inputs/DatePicker";
import DropDownInput from "../Inputs/DropDownInput";
import DateFormat from "dateformat";
import RiderQueationsListContainer from "./RiderQueationsList/RiderQueationsListContainer";
import { Link } from "react-router-dom";

import Loading from "../Utils/Loading";
const questionType = [{ id:1,name: "Unanswered", value: "Unanswered" }, { id:1,name: "Answered", value: "Answered" }, ];
class QuestionsMainScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted_dttm: new Date(),
      isAnswered: false,
      NoOfPages: 0,
      showDropdown:true,
      activePage: 1,
      isLoading:false

    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  setLoading = (loading) =>{
    this.state.isLoading = loading
    
  }

  setQuestionList = (ListLength) => {
      this.setState({
        NoOfPages: Math.trunc((ListLength - 1) / 10) + 1,
        
      });
  };
  onAnswerChange = (e) => {
    
    this.setState({
      isAnswered: e.target.value,
      activePage: 1,
    });
    

  };
  submittedDateHandler = (e) => {
    
    this.setState({
        submitted_dttm: e,
        activePage: 1,
        isAnswered:false,
        showDropdown:false
    }, () => {
        this.setState({
            showDropdown:true
        });
    });
  };
  ChangePage = (page) => {
    console.log("Loading page:",this.state.isLoading)
    if (this.state.activePage != page && !this.state.isLoading) {
      this.setState({
        activePage: page,
      });
    }
  };
  handleNext = () =>{
    if(this.state.activePage < this.state.NoOfPages && !this.state.isLoading){
        this.setState({
            activePage:this.state.activePage+ 1
        })
    }
}
handlePrev = () =>{
    if(this.state.activePage > 1 && !this.state.isLoading){
        this.setState({
            activePage:this.state.activePage- 1
        })
    }
}

  render() {
    const items = [];
    let key =1
    items.push(<Link to="#" key={key} onClick={() => this.ChangePage(1)}   className={this.state.activePage == 1 ? "active-page" : null} >   1 </Link>
    );
    let i = 2;
    let offset = 5;
    if (this.state.activePage > 3) {
      offset = this.state.activePage + 3;
      i = this.state.activePage - 1;
      key=key+1
      items.push(<Link key={key} to="#">{"..."}</Link>);
    }
    if(this.state.activePage + 3 > this.state.NoOfPages && i > 2)
    i = this.state.NoOfPages - 3
    for (
      let index = 0 + i;
      index < this.state.NoOfPages && index < offset;
      index++
    ) {
      key=key+1
      items.push( <Link to="#"  key={index}  onClick={() => this.ChangePage(index)}  className={i == this.state.activePage ? "active-page" : null} >  {i} </Link>);
      i++;
    }
    if (this.state.NoOfPages > this.state.activePage + 3)
    {
      key=key+1
      items.push(<Link key={key} to="#">{"..."}</Link>);
    }
    key=key+1
    items.push(<Link  key={key} to="#"  onClick={() => this.ChangePage(this.state.NoOfPages)} className={this.state.activePage == this.state.NoOfPages ? "active-page" : null}>{this.state.NoOfPages} </Link>);

    
    return (
      <div className="main-question">
        <UploadBannerHeader heading="Customer Support Questions" />
        <div className="question-box">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-md-5 col-lg-4">
              <div className="qustion-inner-b">
                  <div className="label-input">Date</div>
                  <div className="input-b">
                    <DatePicker
                      htmlFor="dateInput"
                      inputValue={this.state.submitted_dttm}
                  
                      maxDate={new Date()}
                      onChange={this.submittedDateHandler}
                    />
                  </div>
              </div>
              </div>
              <div className="col-sm-6 col-md-5 col-lg-4">
              <div className="qustion-inner-b">
              <div className="label-input">Select Questions</div>
                  <div className="input-b">
                      {this.state.showDropdown?
                    <DropDownInput
                      onChange={this.onAnswerChange}
                      FirstValue={"Unanswered"}
                      id="unanswered"
                      data={questionType}
                    />:<Loading/>
                      }
                  </div>
              </div>
              </div>
              <div className="col-md-2 col-lg-4"></div>
            </div>


            <div className="qustion-inner-b">
         
              <div className="row">
                <div className="col-sm-12">
                <div className="rider-c-box">
                <RiderQueationsListContainer
                  activePage={this.state.activePage}
                  setQuestionList={this.setQuestionList}
                  submitted_dttm={DateFormat(
                    this.state.submitted_dttm,
                    "yyyy-mm-dd"
                  )}
                  isAnswered={this.state.isAnswered}
                  setLoading = { this.setLoading}
                />
              </div>
                </div>
              </div>
  
              {this.state.NoOfPages > 1 ? (
                <div className="row">
                  <div className="col-sm-12">              
                           <div className="pagination-box">
                  <div className="pagination-inner-box">
                    <div className="box-pagination">
                      <div className="next-btn">
                        <div className="cont1">
                          <Link id="previousLink" to="#" onClick={this.handlePrev}>prev</Link>
                        </div>
                        <div style={{width:300}} className="count-btn">
                          {
                            items

                          }
                        </div>
                        <div className="cont1">
                          <Link id="nextLink" to="#" onClick ={this.handleNext}>next</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default QuestionsMainScreen;
