export const UserUnsedPointsActions= {
	
	UserUnsedPoints_GET :{
		NEW:"UserUnsedPoints_GET_NEW",
		SUCCESS:"UserUnsedPoints_GET_SUCCESS",
		FAILED:"UserUnsedPoints_GET_FALIURE",
		LOADING:"UserUnsedPoints_GET_LOADING",
		UPDATE:"DisableBusiness_Update_SUCCESS",
	},
	
}


export const UserUnsedPointsStatus ={

	UserUnsedPoints_GET :{
		NEW:"UserUnsedPoints_GET_NEW",
		SUCCESS:"UserUnsedPoints_GET_SUCCESS",
		FAILED:"UserUnsedPoints_GET_FALIURE",
		LOADING:"UserUnsedPoints_GET_LOADING",
	}

}
