import {
    GetRefTransactionTypesStatus,
    GetRefTransactionTypesActions
  } from "./GetRefTransactionTypesConstants";
  
  const GetRefTransactionTypes_initialState = {
    GetRefTransactionTypes_Status: GetRefTransactionTypesStatus.GetRefTransactionTypes_Get.NEW,
    GetRefTransactionTypes_Error: "",
    RefTransactionTypeList:[]
  };


  export default function(state = GetRefTransactionTypes_initialState, action) {
    switch (action.type) {
      case GetRefTransactionTypesActions.GetRefTransactionTypes_Get.LOADING:
        return {
          ...state,
          GetRefTransactionTypes_Status:
            GetRefTransactionTypesStatus.GetRefTransactionTypes_Get.LOADING
        };
      case GetRefTransactionTypesActions.GetRefTransactionTypes_Get.NEW:
        return {
          ...state,
          GetRefTransactionTypes_Status:
            GetRefTransactionTypesStatus.GetRefTransactionTypes_Get.NEW,
        
        };
      case GetRefTransactionTypesActions.GetRefTransactionTypes_Get.SUCCESS:
        return {
          ...state,
          GetRefTransactionTypes_Status:
            GetRefTransactionTypesStatus.GetRefTransactionTypes_Get.SUCCESS,
            RefTransactionTypeList: action.payload
        };
      case GetRefTransactionTypesActions.GetRefTransactionTypes_Get.FAILED:
        return {
          ...state,
          GetRefTransactionTypes_Status:
            GetRefTransactionTypesStatus.GetRefTransactionTypes_Get.FAILED
        };
  
      default:
        return {
          ...state,
          GetRefTransactionTypes_Error: "",
          GetRefTransactionTypes_Status: GetRefTransactionTypesStatus.GetRefTransactionTypes_Get.NEW
        };
    }
  }
  