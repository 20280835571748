
//******************Exchange Rate Server Calls******************;

import {AdministrationLogoutActions} from './AdministrationLogoutconstants';
import store from '../../../Store/store'
import {ROOT_URL} from '../../../Config/Config';

import {failure_messages} from 'Messages';


export const AdministrationLogoutServer = {
  AdministrationLogout:AdministrationLogout,
};

//******************Insert AdministrationLogouts******************;

function AdministrationLogout(data, token){
  
      const request=fetch(ROOT_URL+'/api/applicationUser/logout', {
          method: 'Post',
          mode: 'cors',
          headers: {
            'Content-Type':'application/json',
            'authorization': token
        },
          body: JSON.stringify(data)
          }).then((response)=>{
           response.json().then(response=>{
            store.dispatch({type:AdministrationLogoutActions.AdministrationLogout.SUCCESS,payload:response});
         
             
           });
          }).catch((error) => {
          //  alert(error)
           store.dispatch({type:AdministrationLogoutActions.AdministrationLogout.FAILED, error:failure_messages.unExpectedError});
            })
      return {type:AdministrationLogoutActions.AdministrationLogout.LOADING}

};

  