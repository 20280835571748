//******************Exchange Rate Server Calls******************;

import { GeneratePromotionalPointActions } from "./GeneratePromotionalPointConstants";
import store from "../../Store/store";
import { ROOT_URL,getCookie } from "../../Config/Config";
import { failure_messages } from "Messages";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
export const GeneratePromotionalPointServer = {
  CreatePromotionalPoints: CreatePromotionalPoints
};

//******************Get GeneratePromotionalPoints******************;

function CreatePromotionalPoints(data) {
  fetch(ROOT_URL + "/api/userPoints/CreatePromotionalPoints", {
    method: "POST",
    mode: "cors",
    headers: { 
      'authorization':getCookie("admin_token_customer"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      res
        .json()
        .then(response => {
          console.log("GeneratePromotionalPoint:", response);
          if (response.status == "200") {
            toast.success( response.message)
            store.dispatch({
              type:
                GeneratePromotionalPointActions.GeneratePromotionalPoint_Get
                  .SUCCESS,
              message: response.message
            });
          } else {
            toast.error(response.error)
            store.dispatch({
              type:
                GeneratePromotionalPointActions.GeneratePromotionalPoint_Get
                  .FAILED,
                  error: response.error
            });
          }
        })
        .catch(err => {
          console.log("GeneratePromotionalPointError:", err);
          toast.error(failure_messages.unExpectedError)
          store.dispatch({
            type:
              GeneratePromotionalPointActions.GeneratePromotionalPoint_Get
                .FAILED,
                error: failure_messages.unExpectedError
          });
        });
    })
    .catch(err => {
      console.log("GeneratePromotionalPointError:", err);
      toast.error(failure_messages.unExpectedError)
      store.dispatch({
        type:
          GeneratePromotionalPointActions.GeneratePromotionalPoint_Get.FAILED,
          error: failure_messages.unExpectedError
      });
    });

  return {
    type: GeneratePromotionalPointActions.GeneratePromotionalPoint_Get.LOADING
  };
}
