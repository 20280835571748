export const AdministrationLoginActions= {
	
	AdministrationLogin_GET :{
		NEW:"AdministrationLogin_GET_NEW",
		SUCCESS:"AdministrationLogin_GET_SUCCESS",
		FAILED:"AdministrationLogin_GET_FALIURE",
		LOADING:"AdministrationLogin_GET_LOADING",
		UPDATE:"DisableAdministrationLogin_Update_SUCCESS",
	},
	
}


export const AdministrationLoginStatus ={

	AdministrationLogin_GET :{
		NEW:"AdministrationLogin_GET_NEW",
		SUCCESS:"AdministrationLogin_GET_SUCCESS",
		FAILED:"AdministrationLogin_GET_FALIURE",
		LOADING:"AdministrationLogin_GET_LOADING",
	}

}
