export const GetRefTransactionTypesActions= {
	
	
	GetRefTransactionTypes_Get :{
		NEW:"GetRefTransactionTypes_Get_NEW",
		SUCCESS:"GetRefTransactionTypes_Get_SUCCESS",
		FAILED:"GetRefTransactionTypes_Get_FALIURE",
		LOADING:"GetRefTransactionTypes_Get_LOADING",
		NOT_AUTHORIZED: "GetRefTransactionTypes_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}
	,
	GetRefTransactionTypes_Post :{
		NEW:"GetRefTransactionTypes_Post_NEW",
		SUCCESS:"GetRefTransactionTypes_Post_SUCCESS",
		FAILED:"GetRefTransactionTypes_Post_FALIURE",
		LOADING:"GetRefTransactionTypes_Post_LOADING",
		NOT_AUTHORIZED: "GetRefTransactionTypes_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}
}


export const GetRefTransactionTypesStatus ={

	GetRefTransactionTypes_Get :{
		NEW:"GetRefTransactionTypes_Get_NEW",
		SUCCESS:"GetRefTransactionTypes_Get_SUCCESS",
		FAILED:"GetRefTransactionTypes_Get_FALIURE",
		LOADING:"GetRefTransactionTypes_Get_LOADING",
		NOT_AUTHORIZED: "GetRefTransactionTypes_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	},
	GetRefTransactionTypes_Post :{
		NEW:"GetRefTransactionTypes_Post_NEW",
		SUCCESS:"GetRefTransactionTypes_Post_SUCCESS",
		FAILED:"GetRefTransactionTypes_Post_FALIURE",
		LOADING:"GetRefTransactionTypes_Post_LOADING",
		NOT_AUTHORIZED: "GetRefTransactionTypes_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}

}
