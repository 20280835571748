import { AdministrationLoginStatus, AdministrationLoginActions } from './AdministrationLoginconstants';

const AdministrationLogin_initialState = {
    AdministrationLogin_Status: AdministrationLoginStatus.AdministrationLogin_GET.NEW,
    AdministrationLogin_Message: '',
    AdministrationLoginDetails: {},
}
export default  function  (state = AdministrationLogin_initialState, action) {
    switch (action.type) {
        case AdministrationLoginActions.AdministrationLogin_GET.LOADING:
            return { ...state, AdministrationLogin_Status: AdministrationLoginStatus.AdministrationLogin_GET.LOADING }
            case AdministrationLoginActions.AdministrationLogin_GET.NEW:
                return { ...state, AdministrationLogin_Status: AdministrationLoginStatus.AdministrationLogin_GET.NEW }
    
        case AdministrationLoginActions.AdministrationLogin_GET.FAILED:
            return { ...state,  AdministrationLogin_Status: AdministrationLoginStatus.AdministrationLogin_GET.FAILED, AdministrationLogin_Message:action.error}
        case AdministrationLoginActions.AdministrationLogin_GET.SUCCESS:
          
          return { ...state,  AdministrationLogin_Status: AdministrationLoginStatus.AdministrationLogin_GET.SUCCESS, AdministrationLoginDetails:action.payload, AdministrationLogin_Message:action.message}
          
        
        default:
            return { ...state,
                AdministrationLogin_Status: AdministrationLoginStatus.AdministrationLogin_GET.NEW,
    
            }
    }
}
