export const TransactionHistoryActions= {
	
	TransactionHistory_GET :{
		NEW:"TransactionHistory_GET_NEW",
		SUCCESS:"TransactionHistory_GET_SUCCESS",
		FAILED:"TransactionHistory_GET_FALIURE",
		LOADING:"TransactionHistory_GET_LOADING",
		UPDATE:"DisableBusiness_Update_SUCCESS",
	},
	
}


export const TransactionHistoryStatus ={

	TransactionHistory_GET :{
		NEW:"TransactionHistory_GET_NEW",
		SUCCESS:"TransactionHistory_GET_SUCCESS",
		FAILED:"TransactionHistory_GET_FALIURE",
		LOADING:"TransactionHistory_GET_LOADING",
	}

}
