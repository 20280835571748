import React, {  PureComponent } from "react";
import { OfferInWallerStatus } from "./OfferInWallerConstants";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import {Api_Pagination} from 'RefRecordsConstants'
import Loading from "../../Utils/Loading";
import OfferInWaller from "../OffersinWallet"
export class OfferInWallerView extends PureComponent {
  constructor(props) {
    super(props);
    // alert('wallet')
    this.state = {
      open: true,
      // submitted_dttm:props.submitted_dttm,
      // isAnswered:props.isAnswered,
      activePage:props.activePage
    };
    // if(props.user_id !=="" && props.OfferInWaller_GET_status !==OfferInWallerStatus.OfferInWaller_GET.LOADING){
    //   // alert(nextProps.user_id)
    //   let data={
    //     user_id:props.user_id,
    //     offset:0
    //   }
    //   props.getClaimedCouponsByUserId(data)
    //   props.clear_user_id()
    // }
    // this.getRiderUnAnsweredQuestions()
  }

  // getRiderUnAnsweredQuestions = ()=>{

  //   const data = {
  //     offset : 0,
  //     submitted_dttm :this.props.submitted_dttm,
  //     props:this.props
  //   }

  //   if(this.props.isAnswered == "Answered"){
    
  //     this.props.getRiderAnsweredQuestions(data)
  //   }
  //   else{
  //     this.props.getRiderUnAnsweredQuestions(data)
  //   }
  // }
  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.OfferInWaller_GET_status ===OfferInWallerStatus.OfferInWaller_GET.SUCCESS ||
      nextProps.OfferInWaller_GET_status ===OfferInWallerStatus.OfferInWaller_GET.FAILED
      ){
        nextProps.setLoading(false)
    }

    if(nextProps.user_id_flag && nextProps.OfferInWaller_GET_status !==OfferInWallerStatus.OfferInWaller_GET.LOADING){
      // alert(nextProps.user_id)
      let data={
        user_id:nextProps.user_id,
        offset: (nextProps.activePage - 1)  * Api_Pagination.PointsHistoryLimit
      }
      nextProps.getClaimedCouponsByUserId(data)
      nextProps.clear_user_id()
      nextProps.setLoading(true)
    }
   
  //   if(prevState.submitted_dttm !== nextProps.submitted_dttm || prevState.isAnswered !== nextProps.isAnswered || prevState.activePage !== nextProps.activePage){
  //     const data = {
  //       offset : (nextProps.activePage - 1) * 10,
  //       submitted_dttm :nextProps.submitted_dttm ,
  //       props:nextProps
  //     }
  //     if(nextProps.isAnswered == "Answered"){
    
  //       nextProps.getRiderAnsweredQuestions(data)
  //     }
  //     else{
  //       nextProps.getRiderUnAnsweredQuestions(data)
  //     }
  //     return{
  //       submitted_dttm:nextProps.submitted_dttm,
  //       isAnswered:nextProps.isAnswered,
  //       activePage:nextProps.activePage
  //     }
  //  }
   
   return null;
 }
  getScreen(status) {
    switch (status) {
      case OfferInWallerStatus.OfferInWaller_GET.NEW:
        return (
          <OfferInWaller  data={this.props.OfferInWaller} />
        );

      case OfferInWallerStatus.OfferInWaller_GET.SUCCESS:
      // alert(JSON.stringify(this.props.OffersCount))
      console.log("this.props.OfferInWaller:", this.props.OfferInWaller)
        return (
          <OfferInWaller setNoOfPages={this.props.setNoOfPages} OffersCount={this.props.OffersCount}  data={this.props.OfferInWaller} />
          // this.props.OfferInWaller.length > 0 ?
          // this.props.OfferInWaller.map((item)=>{
          //   return(

          //     <RiderSupportAnsCard key ={item.rider_support_request_id}
          //     email_address = {item.email_address}
          //     submitted_dttm = {this.state.submitted_dttm}
          //     question = {item.question}
          //     response = {item.response}
          //     response_dttm = {item.response_dttm}
          //     isAnswered = {this.state.isAnswered}
          //     rider_support_request_id = {item.rider_support_request_id}
          //     getRiderUnAnsweredQuestions = {this.getRiderUnAnsweredQuestions}
          //     phone = {item.phone}
          //     />
          
          //   )
          // }):
          // this.state.isAnswered == "Answered"?
          // < QuestionsEmptyScreen message = {"No Answered Questions yet"} />
          // :< QuestionsEmptyScreen message = {"No Unanswered Questions yet"} />
          
        );
        break;
      case OfferInWallerStatus.OfferInWaller_GET.FAILED:
        
        toast.error(this.props.OfferInWaller_Error)
        return (
          <OfferInWaller  data={[]} />
           );

      case OfferInWallerStatus.OfferInWaller_GET.LOADING:
     
        return (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Loading />
          </div>
        );
        break;
      default:
        return <div />;
    }
  }
  render() {
    return this.getScreen(this.props.OfferInWaller_GET_status);
  }
}

export default OfferInWallerView;
