
//******************Exchange Rate Server Calls******************;
import {AuthenticationActions} from './AuthenticationConstants';
import store from '../../Store/store'
import {ROOT_URL} from '../../Config/Config';
import {failure_messages} from 'Messages'

export const AuthenticationServer = {
  AuthenticateUser:AuthenticateUser,
};

//******************Insert Authentications******************;

function AuthenticateUser(token){
      const request=fetch(ROOT_URL+'/api/applicationUser/AuthenticateUser', {
          method: 'Post',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', 'x-access-token': token },
          }).then((response)=>{
           response.json().then(response=>{
             
             if(response.status == "200" && !response.AuthError){
            //  alert(JSON.stringify(response))
            store.dispatch({type:AuthenticationActions.Authentication_TOKEN.AUTHORIZED, payload:response.message});
            return;
            }else
            {
              store.dispatch({type:AuthenticationActions.Authentication_TOKEN.NOT_AUTHORIZED, error:response.error});
              return;
              }    
           });
          }).catch((error) => {
          //  alert(error)
           store.dispatch({type:AuthenticationActions.Authentication_TOKEN.FAILED, error:failure_messages.authorizationError});
            })
      return {type:AuthenticationActions.Authentication_TOKEN.LOADING}

};
  