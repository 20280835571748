import {
    GetBusRoutesStatus,
    GetBusRoutesActions
  } from "./GetBusRoutesConstants";
  
  const GetBusRoutes_initialState = {
    GetBusRoutes_Status: GetBusRoutesStatus.GetBusRoutes_Get.NEW,
    GetBusRoutes_Error: "",
    BusRouteList: []
  };
  export default function(state = GetBusRoutes_initialState, action) {
    switch (action.type) {
      case GetBusRoutesActions.GetBusRoutes_Get.LOADING:
        return {
          ...state,
          GetBusRoutes_Status:
            GetBusRoutesStatus.GetBusRoutes_Get.LOADING
        };
      case GetBusRoutesActions.GetBusRoutes_Get.NEW:
        return {
          ...state,
          GetBusRoutes_Status:
            GetBusRoutesStatus.GetBusRoutes_Get.NEW,
        
        };
      case GetBusRoutesActions.GetBusRoutes_Get.SUCCESS:
        return {
          ...state,
          GetBusRoutes_Status:
            GetBusRoutesStatus.GetBusRoutes_Get.SUCCESS,
          BusRouteList: action.payload
        };
      case GetBusRoutesActions.GetBusRoutes_Get.FAILED:
        return {
          ...state,
          GetBusRoutes_Status:
            GetBusRoutesStatus.GetBusRoutes_Get.FAILED
        };
  
      default:
        return {
          ...state,
          GetBusRoutes_Error: "",
          GetBusRoutes_Status: GetBusRoutesStatus.GetBusRoutes_Get.NEW
        };
    }
  }
  