import React, { Component, Fragment } from "react";
import DropDownInput from '../../Inputs/DropDownInput'
class GetTrainStations extends Component {
  render() {
    const {
      inputValue,
      flag,
      // errorMsg,
      onChange,
      TrainStationsList,
      disabled,
      // GetTrainStations
    } = this.props;
  //  alert(inputValue)
    return (
      <Fragment >

                            <DropDownInput
                            data={TrainStationsList}
                            type="text"
                            name="TrainLine Stations"
                            FirstName="Select Train Station"
                            id="selectTrainStation"
                            inputValue={inputValue}
                            flag={flag}
                            errorMsg={"Field Required"}
                            onChange={onChange}
                            />
      </Fragment>
    );
  }
}

export default GetTrainStations;
