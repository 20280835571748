import React, { Component } from 'react'
import './UploadBannerHeader.css'
import {  withRouter } from 'react-router-dom'

class UploadBannerHeader extends Component {
    render() {
        const { 
        heading="Upload Banner" } = this.props;
        return (
            <div className="upload-header-wrap">
              <div className="container">
                  <div className="row">
                      <div className="col-sm-12">

               
                      <div className="upload-header-box1">
                          
                           <div className="upload-header-heading">
                                <h2 className="upload-header-head">{heading}</h2>
                           </div>
                      </div>
              </div>
              </div>
                  </div>
            </div>
        )
    }
}

export default withRouter(UploadBannerHeader)
