import { connect } from "react-redux";
import UserUnsedPointsView from "./UserUnsedPoints_View";
import {UserUnsedPointsServer} from "./server"
import {UserUnsedPointsActions} from './UserUnsedPointsConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        UserUnsedPoints_GET_status:state.UserUnsedPointsReducer.UserUnsedPoints_Status,
        UserUnsedPoints_Error:state.UserUnsedPointsReducer.UserUnsedPoints_Error,
        PointsCount:state.UserUnsedPointsReducer.PointsCount
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        getUserUnsedPointsByUserId: (data) => {

           dispatch( UserUnsedPointsServer.getUserUnsedPointsByUserId(data) )
        },
    
        resetReducerState:()=>{
            dispatch({type:UserUnsedPointsActions.UserUnsedPoints_GET.NEW})
        }
    }
};
const UserUnsedPointsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserUnsedPointsView)

export default UserUnsedPointsContainer;