import React, {  PureComponent } from "react";
import { RiderQueationsListStatus } from "./RiderQueationsListConstants";
import RiderSupportAnsCard from '../../Cards/UploadBannerCard/RiderSupportAnsCard'
import Loading from "../../Utils/Loading";
import QuestionsEmptyScreen from "../QuestionsEmptyScreen"
export class RiderQueationsListView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      submitted_dttm:props.submitted_dttm,
      isAnswered:props.isAnswered,
      activePage:props.activePage
    };
    this.getRiderUnAnsweredQuestions()
  }

  closeSnackBar = () => {
    this.setState({
      open: false,
    });
  };
  closePostSnackBar = () => {
    this.setState({
      open: false,
    });
  };
  getRiderUnAnsweredQuestions = ()=>{

    const data = {
      offset : 0,
      submitted_dttm :this.props.submitted_dttm,
      props:this.props
    }

    if(this.props.isAnswered == "Answered"){
    
      this.props.getRiderAnsweredQuestions(data)
    }
    else{
      this.props.getRiderUnAnsweredQuestions(data)
    }
  }
  static getDerivedStateFromProps(nextProps, prevState){
    if(prevState.submitted_dttm !== nextProps.submitted_dttm || prevState.isAnswered !== nextProps.isAnswered || prevState.activePage !== nextProps.activePage){
      const data = {
        offset : (nextProps.activePage - 1) * 10,
        submitted_dttm :nextProps.submitted_dttm ,
        props:nextProps
      }
      if(nextProps.isAnswered == "Answered"){
    
        nextProps.getRiderAnsweredQuestions(data)
      }
      else{
        nextProps.getRiderUnAnsweredQuestions(data)
      }
      return{
        submitted_dttm:nextProps.submitted_dttm,
        isAnswered:nextProps.isAnswered,
        activePage:nextProps.activePage
      }
   }
   
   return null;
 }
  getScreen(status) {
    switch (status) {
      case RiderQueationsListStatus.RiderQueationsList_GET.NEW:
        return (
            null
        );

      case RiderQueationsListStatus.RiderQueationsList_GET.SUCCESS:
      
        return (
          this.props.RiderQueationsList.length > 0 ?
          this.props.RiderQueationsList.map((item)=>{
            return(

              <RiderSupportAnsCard key ={item.rider_support_request_id}
              email_address = {item.email_address}
              submitted_dttm = {this.state.submitted_dttm}
              question = {item.question}
              response = {item.response}
              response_dttm = {item.response_dttm}
              isAnswered = {this.state.isAnswered}
              rider_support_request_id = {item.rider_support_request_id}
              getRiderUnAnsweredQuestions = {this.getRiderUnAnsweredQuestions}
              phone = {item.phone}
              />
          
            )
          }):
          this.state.isAnswered == "Answered"?
          < QuestionsEmptyScreen message = {"No Answered Questions yet"} />
          :< QuestionsEmptyScreen message = {"No Unanswered Questions yet"} />
          
        );
        break;
      case RiderQueationsListStatus.RiderQueationsList_GET.FAILED:
        
       
        return (
          this.state.isAnswered == "Answered"?
                < QuestionsEmptyScreen message = {"Failed to get list of Answered Questions"} />
                :
                < QuestionsEmptyScreen message = {"Failed to get list of Unanswered Questions"} />
        );

      case RiderQueationsListStatus.RiderQueationsList_GET.LOADING:
     
        return (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Loading />
          </div>
        );
        break;
      default:
        return <div />;
    }
  }
  render() {
    return this.getScreen(this.props.RiderQueationsList_GET_status);
  }
}

export default RiderQueationsListView;
