import React, { Component } from 'react'
import "./CustomerInformation.css"
import SimpleInput from "../Inputs/SimpleInput"
import PointsHistoryContainer from "./PointsHistory/PointsHistoryContainer"
import OfferInWallerContainer from "./OfferInWaller/OfferInWallerContainer"
import TransactionHistoryContainer from "./TransactionHistory/TransactionHistoryContainer"
import { Link } from "react-router-dom";
import {Api_Pagination} from 'RefRecordsConstants'
import UserUnsedPointsContainer from "./UserUnsedPoints/UserUnsedPointsContainer"
class CustomerInformation extends Component {
    constructor(props){
        super(props)
        this.state={
            email:'',
            emailFlag:false,
            emailErrorMsg:'',
            user_id:'',
            showPoints:true,
            showClaimedOffers:false,
            showRedeemOffers:false,
            user_id_flag:false,
            NoOfPages: 0,
            activePage: 1,
            isLoading:false,
            user_search_flag:false
        }
    }
    setLoading = (loading) =>{
        // alert(loading)
        this.state.isLoading = loading
        
      }
    
      clear_user_search_flag = ()=>{
        this.setState({
          user_search_flag:false
        })
      }
    ChangePage = (page) => {
        console.log("Loading page:",this.state.isLoading)
        if (this.state.activePage != page && !this.state.isLoading) {
          this.setState({
            activePage: page,
            user_id_flag:true,
            isLoading:true
          });
        }
      };
      handleNext = () =>{
        if(this.state.activePage < this.state.NoOfPages && !this.state.isLoading){
            this.setState({
                activePage:this.state.activePage+ 1,
                user_id_flag:true,
                isLoading:true
            })
        }
    }
    handlePrev = () =>{
        if(this.state.activePage > 1 && !this.state.isLoading){
            this.setState({
                activePage:this.state.activePage- 1,
                user_id_flag:true,
                isLoading:true
            })
        }
    }

    emailHandler = e => {
        const reg = /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]+[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]+)[\W]*$/;
        const validReg = reg.test(e.target.value);
        let emailFlag =  this.state.emailFlag
        let emailErrorMsg = this.state.emailErrorMsg
        if (e.target.value === ""){
            emailFlag = true
            emailErrorMsg= ""
        }
        else if (validReg) {
            emailFlag = false
        } else {
            emailFlag = true
            emailErrorMsg= "Enter Valid Email"
        }
        this.setState({ email: e.target.value,emailErrorMsg:emailErrorMsg,emailFlag:emailFlag });
        };
setUserId=()=>{
    if(this.state.email==="" ||this.state.emailFlag){
        console.log('invalid email')
    }else{
        this.setState({
            user_id:this.state.email,
            user_id_flag:true,
            user_search_flag:true
        })
    }
}
clear_user_id = ()=>{
    this.setState({
        user_id_flag:false
    })
}


setNoOfPages = (ListLength) => {
    this.setState({
      NoOfPages: Math.trunc((ListLength - 1) / Api_Pagination.PointsHistoryLimit) + 1,
      
    });
};

handleTabChange=(one,two,three)=>{
    let user_id = this.state.user_id
    // if(this.state.email==="" ||this.state.emailFlag)
    //     console.log('invalid email')
    // else
    // user_id = this.state.email
    
    this.setState({
        showPoints:one,
        showClaimedOffers:two,
        showRedeemOffers:three,
        user_id:user_id,
        user_id_flag:true,
        NoOfPages: 0,
        activePage: 1,
   })
}

    render() {
        const items = [];
    items.push(
      <Link to="#" onClick={() => this.ChangePage(1)} className={this.state.activePage == 1 ? "active-page" : null}>
        {" "}
        1{" "}
      </Link>
    );
    let i = 2;
    let offset = 6;
    if (this.state.activePage > 3 && this.state.NoOfPages > 5) {
      offset = this.state.activePage + 3;
      i = this.state.activePage - 1;
      items.push(<Link to="#">{"..."}</Link>);
    }
    if (this.state.activePage + 3 > this.state.NoOfPages && i > 2)
      i = this.state.NoOfPages - 3;
    for (
      let index = 0 + i;
      index < this.state.NoOfPages && index < offset - 1;
      index++
    ) {
      items.push(
        <Link
          to="#"
          key={index}
          onClick={() => this.ChangePage(index)}
          className={i == this.state.activePage ? "active-page" : null}
        >
          {" "}
          {i}{" "}
        </Link>
      );
      i++;
    }
    if (this.state.NoOfPages > this.state.activePage + 2)
      if (this.state.NoOfPages > 5) items.push(<Link to="#">{"..."}</Link>);
    items.push(
      <Link to="#" onClick={() => this.ChangePage(this.state.NoOfPages)} className={   this.state.activePage == this.state.NoOfPages ? "active-page" : null }>
        {this.state.NoOfPages}{" "}
      </Link>
    );
    // alert(this.state.isLoading)
        return (
            <div className="custom_info_wrap">
                <div className="container-fluid">
                    <div className="row">
                        {/* First Row Start */}
                        <div className="col-sm-2 col-md-2 col-lg-3"></div>
                        <div className="col-sm-8 col-md-8 col-lg-6">
                            <div className="erpc_header">
                                <h1>Customer Information</h1>
                            </div>
                        </div>
                        <div className="col-sm-2 col-md-2 col-lg-3"></div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4"></div>
                        <div className="col-sm-4">
                            <div className="wrap_search_box">
                                <div className="input_email">
                                    <SimpleInput
                                        placeholder="Enter Email Search"
                                        value={this.state.email}
                                        onChange={this.emailHandler}
                                        maxLength={'64'}
                                    />
                                </div>
                                <div className="button_search">
                                    <button className="button_main" onClick={this.setUserId}>
                                        Search
                                    </button>
                                </div>
                            </div>
                            <p  className="si_error_text" style={{ display: this.state.emailFlag ? "block" : "none" }}>{this.state.emailErrorMsg}</p>

                        </div>
                        <div className="col-sm-4"></div>
                    </div>
              <h4 className="unused_point">
                Total Unused Points: <b><UserUnsedPointsContainer user_search_flag={this.state.user_search_flag} user_id={this.state.user_id} 
                clear_user_search_flag={this.clear_user_search_flag}
                /></b>
              </h4>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="tabs_wrap">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="home-tab" onClick={()=>this.handleTabChange(true,false,false)}
                                         data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Points History</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="profile-tab" 
                                        onClick={()=>this.handleTabChange(false,true,false)}
                                        data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Offers in Wallet</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="contact-tab" 
                                        onClick={()=>this.handleTabChange(false,false,true)}
                                        data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Transactions History</button>
                                    </li>
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        
                                        <div className="tab_box_table">
                                            {this.state.showPoints?
                                            <PointsHistoryContainer
                                            activePage={this.state.activePage}
                                            setNoOfPages={this.setNoOfPages}
                                            setLoading = { this.setLoading}
                                            user_id_flag={this.state.user_id_flag} clear_user_id={this.clear_user_id} user_id={this.state.user_id}/>
                                            :null}
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                        <div className="tab_box_table">
                                        {this.state.showClaimedOffers?
                                        <OfferInWallerContainer 
                                        activePage={this.state.activePage}
                                            setNoOfPages={this.setNoOfPages}
                                            setLoading = { this.setLoading}
                                        user_id_flag={this.state.user_id_flag} clear_user_id={this.clear_user_id} user_id={this.state.user_id}/>
                                        :null}
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                    <div className="tab_box_table">
                                    {this.state.showRedeemOffers?
                                        <TransactionHistoryContainer
                                        activePage={this.state.activePage}
                                            setNoOfPages={this.setNoOfPages}
                                            setLoading = { this.setLoading}
                                        user_id_flag={this.state.user_id_flag}
                                         clear_user_id={this.clear_user_id} user_id={this.state.user_id}/>
                                        :null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
                <div className='pagination_wrap'>
                <div className="container-fluid">
                {(this.state.NoOfPages > 1 && !this.state.isLoading)? (
                <div className="row">
                  <div className="col-sm-12">              
                           <div className="pagination-box">
                  <div className="pagination-inner-box">
                    <div className="box-pagination">
                      <div className="next-btn">
                        <div className="cont1">
                          <Link id="previousLink" to="#" onClick={this.handlePrev}>prev</Link>
                        </div>
                        <div style={{width:300}} className="count-btn">
                          {
                            items

                          }
                        </div>
                        <div className="cont1">
                          <Link id="nextLink" to="#" onClick ={this.handleNext}>next</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                </div>
              ) : null}
              </div>
              </div>
            </div>
        )
    }
}

export default CustomerInformation
