import { connect } from "react-redux";
import GetBusRoutes_View from "./GetBusRoutes_View";
import { GetBusRoutesServer } from "./server";
import { GetBusRoutesActions } from "./GetBusRoutesConstants";
const mapStateToProps = state => {
  console.log(state);
  return {
    GetBusRoutes_Get_status:
      state.GetBusRoutesReducer.GetBusRoutes_Status,
    BusRouteList:
      state.GetBusRoutesReducer.BusRouteList
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getGetBusRoutes: () => {
      dispatch(GetBusRoutesServer.getGetBusRoutes());
    },
    resetReducerState:async ()=>{
      await dispatch({type:GetBusRoutesActions.GetBusRoutes_Get.LOADING})
       dispatch({type:GetBusRoutesActions.GetBusRoutes_Get.NEW})
     }
  };
};
const GetBusRoutesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GetBusRoutes_View);

export default GetBusRoutesContainer;
