export const GetBusRoutesActions= {
	
	
	GetBusRoutes_Get :{
		NEW:"GetBusRoutes_Get_NEW",
		SUCCESS:"GetBusRoutes_Get_SUCCESS",
		FAILED:"GetBusRoutes_Get_FALIURE",
		LOADING:"GetBusRoutes_Get_LOADING",
		NOT_AUTHORIZED: "GetBusRoutes_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}
	,
	GetBusRoutes_Post :{
		NEW:"GetBusRoutes_Post_NEW",
		SUCCESS:"GetBusRoutes_Post_SUCCESS",
		FAILED:"GetBusRoutes_Post_FALIURE",
		LOADING:"GetBusRoutes_Post_LOADING",
		NOT_AUTHORIZED: "GetBusRoutes_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}
}


export const GetBusRoutesStatus ={

	GetBusRoutes_Get :{
		NEW:"GetBusRoutes_Get_NEW",
		SUCCESS:"GetBusRoutes_Get_SUCCESS",
		FAILED:"GetBusRoutes_Get_FALIURE",
		LOADING:"GetBusRoutes_Get_LOADING",
		NOT_AUTHORIZED: "GetBusRoutes_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	},
	GetBusRoutes_Post :{
		NEW:"GetBusRoutes_Post_NEW",
		SUCCESS:"GetBusRoutes_Post_SUCCESS",
		FAILED:"GetBusRoutes_Post_FALIURE",
		LOADING:"GetBusRoutes_Post_LOADING",
		NOT_AUTHORIZED: "GetBusRoutes_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}

}
