import React, { Component } from "react";
import "./PointsTransfer.css";
import SimpleInput from "../Inputs/SimpleInput";
import PointsHistoryContainer from "../CustomerInformation/PointsHistory/PointsHistoryContainer"
import OfferInWallerContainer from "../CustomerInformation/OfferInWaller/OfferInWallerContainer"
import TransactionHistoryContainer from "../CustomerInformation/TransactionHistory/TransactionHistoryContainer"
import { Link } from "react-router-dom";
import { Api_Pagination } from 'RefRecordsConstants'
import {ROOT_URL, getCookie} from '../../Config/Config';
import UserUnsedPointsContainer from "../CustomerInformation/UserUnsedPoints/UserUnsedPointsContainer"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Loading from "../Utils/LoadingBtn";


import Axios from 'axios';
class PointsTransfer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showEmailResult : false,
      oldEmail: "",
      oldEmailErrorMsg: "Field Required",
      oldEmailFlag: false,
      newEmail: "",
      newEmailFlag: false,
      newEmailErrorMsg: "Field Required",
      user_id: '',
      showPoints: true,
      showClaimedOffers: false,
      showRedeemOffers: false,
      user_id_flag: true,
      NoOfPages: 0,
      activePage: 1,
      isLoading: false,
      isSubmitLoading: false,
      user_search_flag: true

    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  // Handler for Old Email
  oldEmailHandler = (e) => {
    const emailLimit = 1000;
    const reg = /^[\w+\-.%]+@[A-Za-z\d\-.]+\.[A-Za-z]{2,}$/;
    const email = e.target.value;
    const validReg = reg.test(email);

    if (email === "") {
      this.setState({
        oldEmailFlag: true,
        oldEmailErrorMsg: "Field Required",
        oldEmail: email,
      });
    } else if (validReg) {
      this.setState({
        oldEmailFlag: false,
        oldEmailErrorMsg: "",
        oldEmail: email,
      });
    } else {
      this.setState({
        oldEmailFlag: true,
        oldEmailErrorMsg: "Enter a valid email",
        oldEmail: email,
      });
    }

    if (email.length > emailLimit) {
      this.setState({
        oldEmail: "",
        oldEmailFlag: true,
        oldEmailErrorMsg: `Email length should be less than ${emailLimit} characters.`,
      });
    }
  };

  // Handler for New Email
  newEmailHandler = (e) => {
    const emailLimit = 1000;
    const reg = /^[\w+\-.%]+@[A-Za-z\d\-.]+\.[A-Za-z]{2,}$/;
    const email = e.target.value;
    const validReg = reg.test(email);

    if (email === "") {
      this.setState({
        newEmailFlag: true,
        newEmailErrorMsg: "Field Required",
        newEmail: email,
      });
    } else if (validReg) {
      this.setState({
        newEmailFlag: false,
        newEmailErrorMsg: "",
        newEmail: email,
      });
    } else {
      this.setState({
        newEmailFlag: true,
        newEmailErrorMsg: "Enter a valid email",
        newEmail: email,
      });
    }

    if (email.length > emailLimit) {
      this.setState({
        newEmail: "",
        newEmailFlag: true,
        newEmailErrorMsg: `Email length should be less than ${emailLimit} characters.`,
      });
    }
  }; 

  // Send Button Handler
  sendButtonHandler = () => {
    // alert("ksdn")
    // this.setState({
    // showEmailResult: true
    // });

    const { oldEmail, oldEmailFlag, newEmail, newEmailFlag } = this.state;

    if (oldEmailFlag || oldEmail === "") {
      this.setState({
        oldEmailErrorMsg: oldEmailFlag ? "Enter Valid Email" : "Field Required",
        oldEmailFlag: true,
      });
    }

    if (newEmailFlag || newEmail === "") {
      this.setState({
        newEmailErrorMsg: newEmailFlag ? "Enter Valid Email" : "Field Required",
        newEmailFlag: true,
      });
    }

    if (!oldEmailFlag && oldEmail !== "" && !newEmailFlag && newEmail !== "") {
      const data = {
        oldEmail: oldEmail,
        newEmail: newEmail,
      };
// alert(JSON.stringify(ROOT_URL))
      console.log("Form data", data);
      // Handle the form submission logic here
    this.setState({isSubmitLoading:true})
const url = ROOT_URL+'/api/userPoints/pointsTransfer'
      const config = {
  method: 'post', // HTTP method
  url: url,
  headers: { 
    'Authorization': getCookie("admin_token_customer"),
    'Content-Type': 'application/json'
  },
  data: data
};

Axios(config)
  .then(response => {
      toast.success(response?.data?.Message?response?.data?.Message:"Points Transfered Successfully")
    // console.log('Response:', response);
     this.setState({
    showEmailResult: true,
    isSubmitLoading:false
    });
  })
  .catch(error => {
    if(error.response && error.response?.data?.error){
      console.log("error.response",error.response)
          toast.error( error.response?.data?.error)
    }else
    {
      toast.error(error.message)
    }
       
    this.setState({
    isSubmitLoading:false
    });
    console.error('Error:', error);
  });

    }
  };

  setLoading = (loading) => {
    // alert(loading)
    this.state.isLoading = loading

  }

  clear_user_search_flag = () => {
    this.setState({
      user_search_flag: false
    })
  }
  ChangePage = (page) => {
    console.log("Loading page:", this.state.isLoading)
    if (this.state.activePage != page && !this.state.isLoading) {
      this.setState({
        activePage: page,
        user_id_flag: true,
        isLoading: true
      });
    }
  };
  handleNext = () => {
    if (this.state.activePage < this.state.NoOfPages && !this.state.isLoading) {
      this.setState({
        activePage: this.state.activePage + 1,
        user_id_flag: true,
        isLoading: true
      })
    }
  }
  handlePrev = () => {
    if (this.state.activePage > 1 && !this.state.isLoading) {
      this.setState({
        activePage: this.state.activePage - 1,
        user_id_flag: true,
        isLoading: true
      })
    }
  }

  emailHandler = e => {
    const reg = /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]+[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]+)[\W]*$/;
    const validReg = reg.test(e.target.value);
    let emailFlag = this.state.emailFlag
    let emailErrorMsg = this.state.emailErrorMsg
    if (e.target.value === "") {
      emailFlag = true
      emailErrorMsg = ""
    }
    else if (validReg) {
      emailFlag = false
    } else {
      emailFlag = true
      emailErrorMsg = "Enter Valid Email"
    }
    this.setState({ email: e.target.value, emailErrorMsg: emailErrorMsg, emailFlag: emailFlag });
  };
  setUserId = () => {
    if (this.state.email === "" || this.state.emailFlag) {
      console.log('invalid email')
    } else {
      this.setState({
        user_id: this.state.email,
        user_id_flag: true,
        user_search_flag: true
      })
    }
  }
  clear_user_id = () => {
    this.setState({
      user_id_flag: false
    })
  }


  setNoOfPages = (ListLength) => {
    this.setState({
      NoOfPages: Math.trunc((ListLength - 1) / Api_Pagination.PointsHistoryLimit) + 1,

    });
  };

  handleTabChange = (one, two, three) => {
    let user_id = this.state.user_id
    // if(this.state.email==="" ||this.state.emailFlag)
    //     console.log('invalid email')
    // else
    // user_id = this.state.email

    this.setState({
      showPoints: one,
      showClaimedOffers: two,
      showRedeemOffers: three,
      user_id: user_id,
      user_id_flag: true,
      NoOfPages: 0,
      activePage: 1,
    })
  }

  render() {
    const {
      oldEmail,
      oldEmailFlag,
      oldEmailErrorMsg,
      newEmail,
      newEmailFlag,
      newEmailErrorMsg,
    } = this.state;

    const items = [];
    items.push(
      <Link to="#" onClick={() => this.ChangePage(1)} className={this.state.activePage == 1 ? "active-page" : null}>
        {" "}
        1{" "}
      </Link>
    );
    let i = 2;
    let offset = 6;
    if (this.state.activePage > 3 && this.state.NoOfPages > 5) {
      offset = this.state.activePage + 3;
      i = this.state.activePage - 1;
      items.push(<Link to="#">{"..."}</Link>);
    }
    if (this.state.activePage + 3 > this.state.NoOfPages && i > 2)
      i = this.state.NoOfPages - 3;
    for (
      let index = 0 + i;
      index < this.state.NoOfPages && index < offset - 1;
      index++
    ) {
      items.push(
        <Link
          to="#"
          key={index}
          onClick={() => this.ChangePage(index)}
          className={i == this.state.activePage ? "active-page" : null}
        >
          {" "}
          {i}{" "}
        </Link>
      );
      i++;
    }
    if (this.state.NoOfPages > this.state.activePage + 2)
      if (this.state.NoOfPages > 5) items.push(<Link to="#">{"..."}</Link>);
    items.push(
      <Link to="#" onClick={() => this.ChangePage(this.state.NoOfPages)} className={this.state.activePage == this.state.NoOfPages ? "active-page" : null}>
        {this.state.NoOfPages}{" "}
      </Link>
    );

    return (
      <div className="gpp-box">
        <div className="container">
        <div className="row">
            <div className="col-sm-2 col-md-2 col-lg-3"></div>
            <div className="col-sm-8 col-md-8 col-lg-6">
              <div className="erpc_header">
                <h1>Points Transfer</h1>
              </div>
            </div>
            <div className="col-sm-2 col-md-2 col-lg-3"></div>
            </div>
        </div>
        <div 
         style={{
          display: this.state.showEmailResult ? "none" : "block"
        }}
        className="container">
          <div className="row">
         

            <div className="col-sm-2 col-md-2 col-lg-4"></div>
            <div className="col-sm-8 col-md-8 col-lg-4">
              <label className="gpp-date-label">Old Email</label>
              <div className="gpp-point-b">
                <SimpleInput
                  name="oldEmail"
                  id="oldEmailInput"
                  inputValue={oldEmail}
                  placeholder="Enter Email Address"
                  flag={oldEmailFlag}
                  maxLength={1000}
                  errorMsg={oldEmailErrorMsg}
                  onChange={this.oldEmailHandler}
                />
              </div>
            </div>
            <div className="col-sm-2 col-md-2 col-lg-4"></div>

            <div className="col-sm-2 col-md-2 col-lg-4"></div>
            <div className="col-sm-8 col-md-8 col-lg-4">
              <label className="gpp-date-label">New Email</label>
              <div className="gpp-point-b">
                <SimpleInput
                  name="newEmail"
                  id="newEmailInput"
                  inputValue={newEmail}
                  placeholder="Enter Email Address"
                  flag={newEmailFlag}
                  maxLength={1000}
                  errorMsg={newEmailErrorMsg}
                  onChange={this.newEmailHandler}
                />
              </div>
            </div>
            <div className="col-sm-2 col-md-2 col-lg-4"></div>

            <div className="col-sm-2 col-md-2 col-lg-4"></div>
            <div className="col-sm-8 col-md-8 col-lg-4">
              <div className="gpp-box-in">
                <div className="gpp-box-in margin2">
                  <div className="gpp-send-btn">
                    
                    <button
                      id="SendPoint"
                      className="gpp-button"
                      style={{
                        width:100
                      }}
                      onClick={this.sendButtonHandler}
                    >
                      {this.state.isSubmitLoading?<Loading/>:"Save"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-2 col-md-2 col-lg-4"></div>
          </div>

        </div>
        {this.state.showEmailResult?
        <div
        //  style={{
        //   display: this.state.showEmailResult ? "block" : "none"
        // }}
         className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="new-btn-box">
                   <button
                      id="SendPoint"
                      className="gpp-button"
                      onClick={()=>this.setState({  showEmailResult : false,
      oldEmail: "",
      oldEmailErrorMsg: "Field Required",
      oldEmailFlag: false,
      newEmail: "",
      newEmailFlag: false,
      newEmailErrorMsg: "Field Required",
      user_id: '',
      showPoints: true,
      user_id_flag: true,
      NoOfPages: 0,
      activePage: 1,
      isLoading: false,
      isSubmitLoading: false,
      user_search_flag: true})}
                    >
                      New
                    </button>
            </div>
          </div>
        <div className="col-sm-12 col-md-12 col-lg-12">
              <h4 className="unused_point">
                Total Unused Points: <b><UserUnsedPointsContainer // newEmail={this.state.newEmail}
                 user_search_flag={this.state.user_search_flag} user_id={this.state.newEmail}
                  clear_user_search_flag={this.clear_user_search_flag}
                /></b>
              </h4>
            </div>
            <div className="col-sm-12">
              <div className="tabs_wrap">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="home-tab" onClick={() => this.handleTabChange(true, false, false)}
                      data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Points History</button>
                  </li>
                  {/* <li class="nav-item" role="presentation">
                    <button class="nav-link" id="profile-tab"
                      onClick={() => this.handleTabChange(false, true, false)}
                      data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Offers in Wallet</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="contact-tab"
                      onClick={() => this.handleTabChange(false, false, true)}
                      data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Transactions History</button>
                  </li> */}
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

                    <div className="tab_box_table">
                      {this.state.showPoints ?
                        <PointsHistoryContainer
                          activePage={this.state.activePage}
                          setNoOfPages={this.setNoOfPages}
                          setLoading={this.setLoading}
                          user_id_flag={this.state.user_id_flag} clear_user_id={this.clear_user_id} user_id={this.state.newEmail} />
                        : null}
                    </div>
                  </div>
                  <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <div className="tab_box_table">
                      {this.state.showClaimedOffers ?
                        <OfferInWallerContainer
                          activePage={this.state.activePage}
                          setNoOfPages={this.setNoOfPages}
                          setLoading={this.setLoading}
                          user_id_flag={this.state.user_id_flag} clear_user_id={this.clear_user_id} user_id={this.state.user_id} />
                        : null}
                    </div>
                  </div>
                  <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                    <div className="tab_box_table">
                      {this.state.showRedeemOffers ?
                        <TransactionHistoryContainer
                          activePage={this.state.activePage}
                          setNoOfPages={this.setNoOfPages}
                          setLoading={this.setLoading}
                          user_id_flag={this.state.user_id_flag}
                          clear_user_id={this.clear_user_id} user_id={this.state.user_id} />
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='pagination_wrap'>
            <div className="container-fluid">
              {(this.state.NoOfPages > 1 && !this.state.isLoading) ? (
                <div className="row">
                  <div className="col-sm-12">
                    <div className="pagination-box">
                      <div className="pagination-inner-box">
                        <div className="box-pagination">
                          <div className="next-btn">
                            <div className="cont1">
                              <Link id="previousLink" to="#" onClick={this.handlePrev}>prev</Link>
                            </div>
                            <div style={{ width: 300 }} className="count-btn">
                              {
                                items

                              }
                            </div>
                            <div className="cont1">
                              <Link id="nextLink" to="#" onClick={this.handleNext}>next</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>:null}
      </div>
    );
  }
}

export default PointsTransfer;
