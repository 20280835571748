//****************** Http Request status constants ******************;

module.exports = {
  ref_transaction_type: {
    insert: 1,
    update: 2,
    delete: 3,
  },
  refunits: {
    Dollars: 1,
    Points: 2,
    Coupon: 3,
  },
  ref_mode_of_transportation: {
    Bus: 1,
    "Light Rail": 2,
    Train: 3,
    All: 4,
    Rail: 3,
    Event:5,
    "Access Link":6
  },
  
  re_coupon_type: {
    regular: 1,
    Premium: 2,
  },
  ref_role: {
    NJTAdmin: 1,
    SMB: 2,
    NJTRider: 3,
    PartnerSupportAdmin:4,
    CustomerSupportAdmin:5
  },
  ref_points_transaction_type: {
    ticket_point_credit: 1,
    promostional_point_credit: 2,
    coupon_debit: 3,
    bonus_points: 4
  },
  ref_status: {
    Submitted: 1,
    Under_Review: 2,
    Approved: 3,
    "Soft Rejected": 4,
    "Hard Rejected": 5,
    ACTIVE: 6,
    EXPIRED: 7,
    PAUSED: 8,
    CLOSED: 9,
    PENDING:10,
    DECLINED:11,
    PENDINGEXPIRED:12,
    DELETED:13,
    SAVED:14
  },
  ref_transit_service:{
    njt_mobile_user:1,
    njt_access_link_user:2
  },
  ref_transit_companies:{
    NJT:1
  },
  ref_tables: {
    tbl_coupon_batches: 29,
    tbl_business_applications: 21,
  },
  ref_coupon_display_behavior: {
    "Display Offer Code": true,
    "Display QR Code": true,
    "Display Redeem Coupon Button": true,
   
  },
  ref_invoice_configuration_settings: {
    customer_service_phone: 1,
  },
  ref_globals: {
    retailer_term_conditions: "retailer_term_conditions",
    rider_term_conditions: "rider_term_conditions",
    coupon_unclaim_time: "coupon_unclaim_time",
    no_points_text: "no_points_text",
    splash_screen_image: "splash_screen_image",
    rider_help_message: "rider_help_message",
    coupon_create_limit: "coupon_create_limit",
    coupon_create_limit_min:"coupon_create_limit_min",
    display_phone_in_help: "display_phone_in_help",
    phone_global_value: "True",
    coupon_urgency_threshold: "coupon_urgency_threshold",
    invoices_display_limit: "invoices_display_limit",
    annual_program_fee: "annual_program_fee",
    program_fee_type: "program_fee_type",
    default_contract_year: "default_contract_year",
    monthly_program_fee: "monthly_program_fee",
    ad_display_limit:"ad_display_limit",
    Zone_3_Banners_limit:"Zone_3_Banners_limit",
    reward_display_interval:"reward_display_interval",
    coupon_type_id: "coupon_type_id",
    referral_fee_percent: "referral_fee_percent",
    coupon_lifetime_in_months: "coupon_lifetime_in_months",
    retailer_login_session:"retailer_login_session",
    referral_fee_fixed:"referral_fee_fixed",
    referral_fee_type:"referral_fee_type",
    rider_header_logo:"rider_header_logo",
    rider_footer_logo:"rider_footer_logo",
    ref_globals:"ref_globals",
    version:"version",
    zone_1_ads_management:"zone_1_ads_management",
    Zone_1_Banners_limit:"Zone_1_Banners_limit",
    zone_2_ads_management:"zone_2_ads_management",
    Zone_2_Banners_limit:"Zone_2_Banners_limit",
    Display_RiderHome_Actions:"Display_RiderHome_Actions",
    zone_3_ads_management:"zone_3_ads_management",
    zone_4_ads_management:"zone_4_ads_management",
    Zone_4_Banners_limit:"Zone_4_Banners_limit",
    coupon_display_interval:"coupon_display_interval",
    open_registration:"open_registration",
    Display_Coupon_Tag:"Display_Coupon_Tag",
    Submit_Coupons_For_Approval:"Submit_Coupons_For_Approval",
    Allow_Rapid_Coupon_Generation:"Allow_Rapid_Coupon_Generation",
    partner_end_banner_upload:"partner_end_banner_upload",
    default_coupon_display_behavior:"default_coupon_display_behavior",
    is_rapid_registration_on:"is_rapid_registration_on",
    customer_help_settings:"customer_help_settings",
    n_days_point_distribution:"n_days_point_distribution",
    zone_5_ads_management:"zone_5_ads_management",
    zone_5_ads_limit:"zone_5_ads_limit",
    cms_current_customer_toc_version:"cms_current_customer_toc_version",
    show_customer_toc_modal:"show_customer_toc_modal",
    max_media_upload_limit:"max_media_upload_limit",
    max_video_upload_size:"max_video_upload_size",
    max_image_upload_size:"max_image_upload_size",
    active_survey_bonus_points_id:"active_survey_bonus_points_id",
    active_survey_bonus_points_amount:"active_survey_bonus_points_amount",
    access_link_bonus_points:"access_link_bonus_points",
    enable_referral_invoices_flag:"enable_referral_invoices_flag",
    enable_prepaid_offer_flag:"enable_prepaid_offer_flag",
    enable_feature_tracking:"enable_feature_tracking"
  },
  coupon_transaction_subtype_id: {
    PercentageType: 1,
    DollarType: 2
  },
  AcceptImages:".gif, .jpg, .png, .jpeg, .apng, .avif, .jfif, .pjpeg, .pjp, .svg, .webp, .bmp, .ico, .cur, .tif, .tiff",
  AcceptVideos:".mp4",
  coupon_transaction_type_id: {
    prepaid: 2,
    discount: 1,
    buy_x_gey_y:3
  },
  
  fee_type: {
    annual: "annual",
    monthly: "monthly",
  },
  subscription_type: {
    auto_ship: 1,
  },
  Users:{
    Admin:"Admin",
    Retailer:"Partner",
    Rider:"Customer"
  },
  Timer:{ // constansts for timer settings in our app
    UnClaimed:30 // Time in minutes, for Coupons that a user can unclaim after first purchasing it.
},
branding:{
  NJTBranding: '1',
  TransitPerxBranding: '2'
},
// ------------------------------------------------
// Naming Convention for Help Text Tags
// (portalName) + (screenName) + (uniqueHelpTag)
// ------------------------------------------------
helpTextTags:{
  partner_portal:{
    generateUniqueCodes:"partnerPortal_offerCreationForm_generateUniqueCodes",
    sameOfferCodes:"partnerPortal_offerCreationForm_sameOfferCodes",
    uploadOfferCodes:"partnerPortal_offerCreationForm_uploadOfferCodes",
    quantityField:"partnerPortal_offerCreationForm_quantityField",
    discPercentage:"partnerPortal_offerCreationForm_discPercentage",
    discDollar:"partnerPortal_offerCreationForm_discDollar",
    minPurchaseAmount:"partnerPortal_offerCreationForm_minPurchaseAmount",
    offerValue:"partnerPortal_offerCreationForm_offerValue",
    uploadOfferImage:"partnerPortal_offerCreationForm_uploadOfferImage",
    appendOfferCodeToURL:"partnerPortal_offerCreationForm_appendOfferCodeToURL",
    offerTitle:"partnerPortal_offerCreationForm_offerTitle",
    termAndConditions:"partnerPortal_offerCreationForm_termAndConditions",
    yearField:"partnerPortal_offerCreationForm_yearField",
    offerStartDate:"partnerPortal_offerCreationForm_offerStartDate",
    offerExpirationDate:"partnerPortal_offerCreationForm_offerExpirationDate",
    offerTypeLabel:"partnerPortal_offerCreationForm_offerTypeLabel",
    showURLCheckBox:"partnerPortal_offerCreationForm_showURLCheckBox",
    showQueryStringCheckBox:"partnerPortal_offerCreationForm_showQueryStringCheckBox",
    urlPreview:"partnerPortal_offerCreationForm_urlPreview",
    buyXQuantityField:"partnerPortal_offerCreationForm_buyXQuantityField",
    freeYQuantityField:"partnerPortal_offerCreationForm_freeYQuantityField",
    offerDescription:"partnerPortal_offerCreationForm_offerDescription",
    rewardWalletCheckBox:"partnerPortal_offerCreationForm_rewardWalletCheckBox",
  }
},
supportedLanguages:{
  English:{
    iso_language_name:"English",
    iso_language_code:"en"
  }
},
'ref_table': {
    'ref_coupon_types':1,
     'ref_face_values':2,
     'ref_mode_of_transportation':3,
     'ref_months':4,
     'ref_points_transaction_type':5,
     'ref_roles':6,
     'ref_stations':7,
     'ref_tables':8,
     'ref_transaction_type':9,
     'ref_units':10,
     'ref_years':11,
     'tbl_exchange_rates':12,
     'tbl_application_users':13,
     'tbl_businesses':14,
     'tbl_coupon_contracts':15,
     'tbl_coupon_quotas':16,
     'tbl_coupons':17,
     'tbl_mytix_users':18,
     'tbl_points':19,
     'tbl_tickets':20,
     'tbl_business_applications':21,
     'tbl_promotional_points_log':22
},
'Api_Pagination':{
    Limit:100,
    OffersLimit:5,
    PointsHistoryLimit:25,
    RiderQuestionsLimit:10,
    NotificationHistoryLimit:20,
    InvoiceListLimit:20,
    UsersListLimit:20,
    ApplicantsLimit:20,
    CouponApprovalLimit:20
},
'AD_TYPE': {
    NATIVE_AD :"1",
    KEVEL_AD :"2",
    GOOGLE_AD :"3",
},
"Analytics_Events":{
  E101:"E101",
  // E1021:"E1021",
  // E1022:"E1022",
  E103:"E103",
  E104:"E104",
  E105:"E105",
  E106:"E106",
  E107:"E107",
  E1071:"E1071",
  E108:"E108",
  E109:"E109",
  E1091:"E1091",
  E1092:"E1092",
  E110:"E110",
  E111:"E111",
  E112:"E112",
  E113:"E113",
  E114:"E114",
  E115:"E115",
  E116:"E116",
  E1161:"E1161",
  E1162:"E1162",
  E1171:"E1171",
  E1172:"E1172",
  E140:"E140",
  E118:"E118",
  E1081:"E1081",
  E1082:"E1082",
  // E29:"E119",
  // E30:"E120",
  E121:"E121",
  E1014:"E1014",
  E1016:"E1016",
  E1015:'E1015',
  E1019:'E1019',
  E1011:"E1011",
  E1012:"E1012",
  E1021:"E1021",
  E1023:"E1023",
  E1024:"E1024",
  E1013:"E1013",
  E1018:"E1018",
  E1022:"E1022",
  E130:"E130",
  E131:"E131",
  E132:"E132",
  E133:"E133",
  E134:"E134",
  E135:"E135",
  E201:"E201",
  E202:"E202",
  E203:"E203",
  E204:"E204",
  E205:"E205",
  E206:"E206",
  E207:"E207",
  E300:"E300",
  E211:"E211",
  E212:"E212",
  E213:"E213",
  E210:"E210",
  E214:"E214"
},
ref_partner_events:{
  PE100:'PE100',
  PE101:'PE101',
  PE1011:'PE1011',
  PE102:'PE102',
  PE103:'PE103',
  PE104:'PE104',
  PE105:'PE105',
  PE1051:'PE1051',
  PE106:'PE106',
  PE1061:'PE1061',
  PE1062:'PE1062',
  PE10621:'PE10621',
  PE107:'PE107',
  PE108:'PE108',
  PE109:'PE109',
  PE110:'PE110',
  PE111:'PE111',
  PE112:'PE112',
  PE113:'PE113',
  PE114:'PE114',
  PE115:'PE115',
  PE116:'PE116',
  PE117:'PE117',
  PE118:'PE118',
  PE119:'PE119',
  PE120:'PE120',
  PE1201:'PE1201',
  PE1202:'PE1202'
}
};
