import { AdministrationLogoutStatus, AdministrationLogoutActions } from './AdministrationLogoutconstants';

const AdministrationLogout_initialState = {
    AdministrationLogout_Status: AdministrationLogoutStatus.AdministrationLogout.NEW,

    AdministrationLogout_Error: '',
    AdministrationLogoutDetails: [],
}
export default  function  (state = AdministrationLogout_initialState, action) {
    switch (action.type) {
        case AdministrationLogoutActions.AdministrationLogout.LOADING:
            return { ...state, AdministrationLogout_Status: AdministrationLogoutStatus.AdministrationLogout.LOADING }
            case AdministrationLogoutActions.AdministrationLogout.NEW:
                return { ...state, AdministrationLogout_Status: AdministrationLogoutStatus.AdministrationLogout.NEW }
    
        case AdministrationLogoutActions.AdministrationLogout.FAILED:
            return { ...state,  AdministrationLogout_Status: AdministrationLogoutStatus.AdministrationLogout.FAILED, AdministrationLogout_Error:action.error}
        case AdministrationLogoutActions.AdministrationLogout.SUCCESS:
          
          return { ...state,  AdministrationLogout_Status: AdministrationLogoutStatus.AdministrationLogout.SUCCESS, AdministrationLogoutDetails:action.payload}
          
        
        default:
            return { ...state,
                AdministrationLogout_Status: AdministrationLogoutStatus.AdministrationLogout.NEW,
            }
    }
}
