import { connect } from "react-redux";
import GetTrainStations_View from "./GetTrainStations_View";
import { GetTrainStationsServer } from "./server";
import { GetTrainStationsActions } from "./GetTrainStationsConstants";
const mapStateToProps = state => {
  console.log(state);
  return {
    GetTrainStations_Get_status:
      state.GetTrainStationsReducer.GetTrainStations_Status,
    TrainStationsList:
      state.GetTrainStationsReducer.TrainStationsList
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getGetTrainStations: () => {
      dispatch(GetTrainStationsServer.getGetTrainStations());
    },
    resetReducerState:async ()=>{
     await dispatch({type:GetTrainStationsActions.GetTrainStations_Get.LOADING})
      dispatch({type:GetTrainStationsActions.GetTrainStations_Get.NEW})
    }
  };
};
const GetTrainStationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GetTrainStations_View);

export default GetTrainStationsContainer;
