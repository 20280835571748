
//******************Exchange Rate Server Calls******************;

import {SendAnswerActions} from './SendAnswerConstants';
import store from '../../../Store/store'
import {ROOT_URL,getCookie} from '../../../Config/Config';
import {failure_messages} from "Messages"


export const SendAnswerServer = {
  sendAnswer:sendAnswer,
};

//******************Insert SendAnswers******************;

function sendAnswer(data){
      const request=fetch(ROOT_URL+'/api/support/sendAnswerOfRiderQuestion', {
          method: 'Post',
          mode: 'cors',
          headers: { 
            'authorization':getCookie("admin_token_customer"),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
          }).then((response)=>{
           response.json().then(response=>{
             if(response.status == "200"){
            store.dispatch({type:SendAnswerActions.SendAnswer_POST.SUCCESS,payload:response.data, message:response.message});
            return ;
            }else
            {
              store.dispatch({type:SendAnswerActions.SendAnswer_POST.FAILED, error:response.error});
              return ;
              }    
           });
          }).catch((error) => {
           store.dispatch({type:SendAnswerActions.SendAnswer_POST.FAILED, error:failure_messages.unExpectedError});
            })
      return {type:SendAnswerActions.SendAnswer_POST.LOADING}

};
  
