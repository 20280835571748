import React, { Component } from "react";
import { SendAnswerStatus } from "./SendAnswerConstants";
import SendModal from '../../Cards/UploadBannerCard/SendModal'
import Loading from "../../Utils/Loading";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
export class SendAnswerView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      showModal:false,
      isLoading:false
    };
  
  }
  handleCloseModal = () =>{
    this.setState({
      showModal:false
    })
    this.props.getRiderUnAnsweredQuestions()
  }
  handleOpenModal = () =>{
    this.setState({
      showModal:true
    })
  }
  sendAnswer = () =>{
  
    const Responce = this.props.Responce.trim()
    if(Responce.length > 0){
     const data = {
        rider_support_request_id: this.props.rider_support_request_id,
        response: Responce,
        email_address: this.props.email_address,
        question : this.props.question,
        phone : this.props.phone
      }
     this.state.isLoading = true
     this.props.sendAnswer(data)
    
    }
  }
  getScreen(status) {
    switch (status) {
      case SendAnswerStatus.SendAnswer_POST.NEW:
        return (
          <SendModal sendAnswer = {this.sendAnswer} showModal = {this.state.showModal}
          handleCloseModal ={this.handleCloseModal}
          handleOpenModal = {this.handleOpenModal}
          />
        );

      case SendAnswerStatus.SendAnswer_POST.SUCCESS:
        if(this.state.isLoading)
      { 
        this.state.isLoading = false
         toast.success(this.props.SendAnswer_Message)
        this.props.resetReducerState()
        this.props.getRiderUnAnsweredQuestions()
      }
        return (
          <SendModal sendAnswer = {this.sendAnswer} showModal = {this.state.showModal}
          handleCloseModal ={this.handleCloseModal}
          handleOpenModal = {this.handleOpenModal}
          />
        );
        break;
      case SendAnswerStatus.SendAnswer_POST.FAILED:
        if(this.state.isLoading)
        { 
          this.state.isLoading = false
         toast.error(this.props.SendAnswer_Message)
         this.props.resetReducerState()
         this.props.getRiderUnAnsweredQuestions()
        }
        return (
          <SendModal sendAnswer = {this.sendAnswer} showModal = {this.state.showModal}
          handleCloseModal ={this.handleCloseModal}
          handleOpenModal = {this.handleOpenModal}
          />
        );

      case SendAnswerStatus.SendAnswer_POST.LOADING:
          if(this.state.isLoading)
        return (
          <div
            style={{
              textAlign: "flex-end",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingLeft:25,
              paddingBottom:6,
              paddingRight:25
              
            }}
          >
            <Loading />
          </div>
        );
        else
        return (
          <SendModal sendAnswer = {this.sendAnswer} showModal = {this.state.showModal}
          handleCloseModal ={this.handleCloseModal}
          handleOpenModal = {this.handleOpenModal}
          />
        );
        break;
      default:
        return <div />;
    }
  }
  render() {
    return this.getScreen(this.props.SendAnswer_POST_status);
  }
}

export default SendAnswerView;
