import React, { Component, Fragment } from "react";
import "./AdministrationMain.css";
import { Link, withRouter } from "react-router-dom";
import { STATIC_IMAGES_URL,ADMIN_END_BRANDING_IMAGE_URL } from "../../Config/Config";


class AdministrationMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }


  render() {
    return (
      <Fragment>
        <div className="header-slider">
          <img
            src={STATIC_IMAGES_URL + "admin_njtransit_banner.jpg"}
            alt="not found"
          />
        </div>
        <div className="adm_container">
          <div className="admin-inner-container">
            <h1 className="adm_h1">Administration - Main</h1>
           
            
            <div className="main-section-box">
              <div className="main-col1">
              <div className="col-box">
                  <h4 className="link-heading">Customer Support</h4>
                  <div className="link-bo">
                    <p className="adm-p-link margin">
                    <span className="dot-star"><img src={ADMIN_END_BRANDING_IMAGE_URL+'1595487026493-Magenta-Star.png'} alt="not found" /></span> <Link className="adm_link1" to="/QuestionsMainScreen">
                        Customer Support Questions
                      </Link>
                    </p>
                    <p className="adm-p-link margin">
                 
                    </p>
                  </div>
                  
                </div>
              </div>
              <div className="main-col1  margin-third">
                <div className="col-box">
                  <h4 className="link-heading">Point Management</h4>
                  <div className="link-bo">
                    <p className="adm-p-link margin">
                    <span className="dot-star"><img src={ADMIN_END_BRANDING_IMAGE_URL+'1595487026493-Magenta-Star.png'} alt="not found" /></span> <Link className="adm_link1" to="Generate_promotional_points">
                        Generate Promotional Points
                      </Link>
                    </p>
                   
                  </div>
                </div>
              </div>
        
            </div>

  
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(AdministrationMain);
