//******************Exchange Rate Server Calls******************;

import { GetTrainStationsActions } from "./GetTrainStationsConstants";
import store from "../../../Store/store";
import { ROOT_URL ,getCookie} from "../../../Config/Config";

export const GetTrainStationsServer = {
  getGetTrainStations: getGetTrainStations
};

//******************Get GetTrainStationss******************;

function getGetTrainStations() {
  const res = fetch(ROOT_URL + "/api/userPoints/getStations", {
    method: "POST",
    mode: "cors",
    headers: { 
      'authorization':getCookie("admin_token_customer"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  })
    .then(res => {
      res
        .json()
        .then(response => {
          console.log("GetTrainStations:", response);
          if(response.status == "200")
          {

          store.dispatch({
            type: GetTrainStationsActions.GetTrainStations_Get.SUCCESS,
            payload: response.data
          })
        }else{
          store.dispatch({
            type: GetTrainStationsActions.GetTrainStations_Get.FAILED
          });
        }
        })
        .catch(err => {
          console.log("GetTrainStationsError:", err);
          store.dispatch({
            type: GetTrainStationsActions.GetTrainStations_Get.FAILED
          });
        });
    })
    .catch(err => {
      console.log("GetTrainStationsError:", err);
      store.dispatch({
        type: GetTrainStationsActions.GetTrainStations_Get.FAILED
      });
    });

  return { type: GetTrainStationsActions.GetTrainStations_Get.LOADING };
}
