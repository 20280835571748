import React, { Component, Fragment } from "react";
import DropDownInput from '../../Inputs/DropDownInput'
class GetRefTransactionTypes extends Component {
  render() {
    const {
      inputValue,
      flag,
      onChange,
      RefTransactionTypeList,
      
    } = this.props;

    console.log("data",RefTransactionTypeList)

    return (
      <Fragment >

                                   <DropDownInput
                            data={RefTransactionTypeList}
                            type="text"
                            name="Bus Routes"
                            FirstValue="Select Bus Route"
                            id="selectBusRoute"
                            inputValue={inputValue}
                            flag={flag}
                            errorMsg={"Field Required"}
                            onChange={onChange}

                            />
      </Fragment>
    );
  }
}

export default GetRefTransactionTypes;
