
//******************Exchange Rate Server Calls******************;

import {TransactionHistoryActions} from './TransactionHistoryConstants';
import store from '../../../Store/store'
import {ROOT_URL,getCookie} from '../../../Config/Config';
import {failure_messages} from "Messages"


export const TransactionHistoryServer = {
  getRedeemedCouponsByUserId:getRedeemedCouponsByUserId,
 
};

//******************Insert TransactionHistorys******************;

function getRedeemedCouponsByUserId(data){
  // data.props.setLoading(true)
      const request=fetch(ROOT_URL+'/api/customer/getRedeemedCouponsByUserId', {
          method: 'Post',
          mode: 'cors',
          headers: { 
            'authorization':getCookie("admin_token_customer"),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
          }).then((response)=>{
           response.json().then(response=>{
             if(response.status == "200"){
              // data.props.setLoading(false)
          
              // if(data.props.setQuestionList && data.props.activePage == 1){
              //   data.props.setQuestionList(response.QuestionsCount)
              // }
            store.dispatch({type:TransactionHistoryActions.TransactionHistory_GET.SUCCESS,payload:response.data, count:response.count})// , QuestionsCount:response.QuestionsCount});
            return ;
            }else
            {
              // data.props.setLoading(false)
              store.dispatch({type:TransactionHistoryActions.TransactionHistory_GET.FAILED, error:response.error});
              return ;
              }    
           });
          }).catch((error) => {
            // if(data.props.setQuestionList){
            //   data.props.setQuestionList(0)
            // }
            // data.props.setLoading(false)
           store.dispatch({type:TransactionHistoryActions.TransactionHistory_GET.FAILED, error:failure_messages.unExpectedError});
            })
      return {type:TransactionHistoryActions.TransactionHistory_GET.LOADING}

};
  