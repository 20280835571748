import React, { Component } from 'react'
import '../RiderSupportQuestions/RiderSupportQuestions.css'

class QuestionsEmptyScreen extends Component {
    render() {
        const {
            message ="No Questions Submitted Yet"
        } = this.props
        return (
            <div className="question-empty-box">
                <div className="question-head">
        <h3 className="question-h">{message}</h3>
                </div>
            </div>
        )
    }
}

export default QuestionsEmptyScreen
