import { combineReducers } from 'redux';
import AuthenticationReducer from '../Components/Authentication/AuthenticationReducer';
import AdministrationLoginReducer from '../Components/AdministrationLoginPage/AdministrationLoginReducer';
import AdministrationLogoutReducer from '../Components/AdministrationMain/AdministrationLogout/AdministrationLogoutReducer';
import GeneratePromotionalPointReducer from '../Components/GeneratePromotionalPoint/reducer';
import GetBusRoutesReducer from '../Components/GeneratePromotionalPoint/GetBusRoutes/reducer';
import GetTrainStationsReducer from '../Components/GeneratePromotionalPoint/GetTrainStations/reducer';
import PointsHistoryReducer from '../Components/CustomerInformation/PointsHistory/PointsHistoryReducer';
import RiderQueationsListReducer from '../Components/RiderSupportQuestions/RiderQueationsList/RiderQueationsListReducer';
import SendAnswerReducer from '../Components/RiderSupportQuestions/SendAnswer/SendAnswerReducer';
import GetRefTransactionTypesReducer from '../Components/GeneratePromotionalPoint/GetRefTransactionTypes/reducer'
import OfferInWallerReducer from '../Components/CustomerInformation/OfferInWaller/OfferInWallerReducer';
import TransactionHistoryReducer from '../Components/CustomerInformation/TransactionHistory/TransactionHistoryReducer';
import UserUnsedPointsReducer from '../Components/CustomerInformation/UserUnsedPoints/UserUnsedPointsReducer';


const reducer = combineReducers({
  AuthenticationReducer,
  AdministrationLoginReducer,
  AdministrationLogoutReducer,
  GeneratePromotionalPointReducer,
  GetBusRoutesReducer,
  GetTrainStationsReducer,
  RiderQueationsListReducer,
  SendAnswerReducer,
  GetRefTransactionTypesReducer,
  PointsHistoryReducer,
  OfferInWallerReducer,
  TransactionHistoryReducer,
  UserUnsedPointsReducer
});
export default reducer;
