import React from 'react'
import './TextAreaInput.css'
function TextAreaInput(props) {

    const {inputValue, placeholder, name = "name", onChange, errorMsg = 'errorMsg', flag = false, containerStyle, maxLength, disabled, id="textArea"} = props
    return (
        <div className="tai_container" style={containerStyle}> 
            <div className="tai_input_container">
                <textarea  id={id} className="tai_input" placeholder={placeholder} name={name} value={inputValue} onChange={onChange} maxLength={maxLength} disabled={disabled}/>
                    
                <p className="tai_error_text" style={{ display: flag ? 'block' : 'none' }}>{errorMsg}</p>
                {maxLength?
                    <p className="tai_label" style={{flex:1,alignSelf:'flex-end', textAlign:'right'}}>{inputValue?.length?inputValue.length:0} / {maxLength}</p>:
                    null
                }
            </div>
        </div>
    )
}

export default TextAreaInput