export const AuthenticationActions= {
	
	Authentication_TOKEN :{
		NEW:"Authentication_TOKEN_NEW",
		AUTHORIZED:"Authentication_TOKEN_AUTHORIZED",
		NOT_AUTHORIZED:"Authentication_TOKEN_NOT_AUTHORIZED",
		LOADING:"Authentication_TOKEN_LOADING",
		FAILED:"Authentication_TOKEN_FAILED",
	},
	
}


export const AuthenticationStatus ={

	Authentication_TOKEN :{
		NEW:"Authentication_TOKEN_NEW",
		AUTHORIZED:"Authentication_TOKEN_AUTHORIZED",
		NOT_AUTHORIZED:"Authentication_TOKEN_NOT_AUTHORIZED",
		LOADING:"Authentication_TOKEN_LOADING",
		FAILED:"Authentication_TOKEN_FAILED",
	}

}
