export const PointsHistoryActions= {
	
	PointsHistory_GET :{
		NEW:"PointsHistory_GET_NEW",
		SUCCESS:"PointsHistory_GET_SUCCESS",
		FAILED:"PointsHistory_GET_FALIURE",
		LOADING:"PointsHistory_GET_LOADING",
		UPDATE:"DisableBusiness_Update_SUCCESS",
	},
	
}


export const PointsHistoryStatus ={

	PointsHistory_GET :{
		NEW:"PointsHistory_GET_NEW",
		SUCCESS:"PointsHistory_GET_SUCCESS",
		FAILED:"PointsHistory_GET_FALIURE",
		LOADING:"PointsHistory_GET_LOADING",
	}

}
