import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from "./Store/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import AuthenticationContainer from "./Components/Authentication/AuthenticationContainer"
import { CookiesProvider } from 'react-cookie';
import { toast } from 'react-toastify';
toast.configure({
  autoClose: 4000,
  draggable: false,
  pauseOnHover: false,
  pauseOnFocusLoss:false
});
ReactDOM.render(
  <CookiesProvider>
  <BrowserRouter>
    <Provider store={store}>
    
        <App />
    <AuthenticationContainer />
    
    </Provider>
  </BrowserRouter>
  </CookiesProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
