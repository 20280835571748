import { connect } from "react-redux";
import OfferInWallerView from "./OfferInWaller_View";
import {OfferInWallerServer} from "./server"
import {OfferInWallerActions} from './OfferInWallerConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        OfferInWaller_GET_status:state.OfferInWallerReducer.OfferInWaller_Status,
        OfferInWaller:state.OfferInWallerReducer.OfferInWallerDetails,
        OfferInWaller_Error:state.OfferInWallerReducer.OfferInWaller_Error,
        OffersCount:state.OfferInWallerReducer.OffersCount
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        getClaimedCouponsByUserId: (data) => {

           dispatch( OfferInWallerServer.getClaimedCouponsByUserId(data) )
        },
    
        resetReducerState:()=>{
            dispatch({type:OfferInWallerActions.OfferInWaller_GET.NEW})
        }
    }
};
const OfferInWallerContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(OfferInWallerView)

export default OfferInWallerContainer;