import { connect } from "react-redux";
import AuthenticationView from "./Authentication_View";
import {AuthenticationServer} from "./server"
import { withCookies } from 'react-cookie';
import {AuthenticationActions} from './AuthenticationConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        Authentication_TOKEN_status:state.AuthenticationReducer.Authentication_Status,
        Authentication_Message:state.AuthenticationReducer.Authentication_Message,

    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        AuthenticateUser: (token) => {

           dispatch( AuthenticationServer.AuthenticateUser(token) )
        },
        resetReducerState : () =>{
            dispatch( {type:AuthenticationActions.Authentication_TOKEN.NEW} )
        }
    }
};
const AuthenticationContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthenticationView)


export default withCookies(AuthenticationContainer);