import React, { Component } from 'react'
import "../Utils/LoadingBtn.css"
class LoadingBtn extends Component {
    render() {
        return (
            <div className="loading-btn">
               <div className="spinner-btn-border loading-btn-color"></div>
            </div>
        )
    }
}

export default LoadingBtn
