import { connect } from "react-redux";
import RiderQueationsListView from "./RiderQueationsList_View";
import {RiderQueationsListServer} from "./server"
import {RiderQueationsListActions} from './RiderQueationsListConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        RiderQueationsList_GET_status:state.RiderQueationsListReducer.RiderQueationsList_Status,
        RiderQueationsList:state.RiderQueationsListReducer.RiderQueationsListDetails,
        RiderQueationsList_Error:state.RiderQueationsListReducer.RiderQueationsList_Error,
        QuestionsCount:state.RiderQueationsListReducer.QuestionsCount
        
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        getRiderUnAnsweredQuestions: (data) => {

           dispatch( RiderQueationsListServer.getRiderUnAnsweredQuestions(data) )
        },
        getRiderAnsweredQuestions: (data) => {

            dispatch( RiderQueationsListServer.getRiderAnsweredQuestions(data) )
         },
        resetReducerState:()=>{
            dispatch({type:RiderQueationsListActions.RiderQueationsList_GET.NEW})
        }
    }
};
const RiderQueationsListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(RiderQueationsListView)

export default RiderQueationsListContainer;