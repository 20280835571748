import {
    GeneratePromotionalPointStatus,
    GeneratePromotionalPointActions
  } from "./GeneratePromotionalPointConstants";
  
  const GeneratePromotionalPoint_initialState = {
    GeneratePromotionalPoint_Status: GeneratePromotionalPointStatus.GeneratePromotionalPoint_Get.NEW,
    GeneratePromotionalPoint_Message: "",
    GeneratePromotionalPointDetails: []
  };
  export default function(state = GeneratePromotionalPoint_initialState, action) {
    switch (action.type) {
      case GeneratePromotionalPointActions.GeneratePromotionalPoint_Get.LOADING:
        return {
          ...state,
          GeneratePromotionalPoint_Status:
            GeneratePromotionalPointStatus.GeneratePromotionalPoint_Get.LOADING
        };
      case GeneratePromotionalPointActions.GeneratePromotionalPoint_Get.NEW:
        return {
          ...state,
          GeneratePromotionalPoint_Status:
            GeneratePromotionalPointStatus.GeneratePromotionalPoint_Get.NEW,
          GeneratePromotionalPointDetails: []
        };
      case GeneratePromotionalPointActions.GeneratePromotionalPoint_Get.SUCCESS:
        return {
          ...state,
          GeneratePromotionalPoint_Status:
            GeneratePromotionalPointStatus.GeneratePromotionalPoint_Get.SUCCESS,
            GeneratePromotionalPoint_Message: action.message
        };
      case GeneratePromotionalPointActions.GeneratePromotionalPoint_Get.FAILED:
        return {
          ...state,
          GeneratePromotionalPoint_Status:
            GeneratePromotionalPointStatus.GeneratePromotionalPoint_Get.FAILED,
            GeneratePromotionalPoint_Message:action.error
        };
  
      default:
        return {
          ...state,
          GeneratePromotionalPoint_Message: "",
          GeneratePromotionalPoint_Status: GeneratePromotionalPointStatus.GeneratePromotionalPoint_Get.NEW
        };
    }
  }
  