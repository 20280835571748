import { AuthenticationStatus, AuthenticationActions } from './AuthenticationConstants';

const Authentication_initialState = {
    Authentication_Status: AuthenticationStatus.Authentication_TOKEN.NEW,
    Authentication_Message: '',
}
export default  function  (state = Authentication_initialState, action) {
    switch (action.type) {
        case AuthenticationActions.Authentication_TOKEN.LOADING:
            return { ...state, Authentication_Status: AuthenticationStatus.Authentication_TOKEN.LOADING }
            case AuthenticationActions.Authentication_TOKEN.NEW:
                return { ...state, Authentication_Status: AuthenticationStatus.Authentication_TOKEN.NEW }

                case AuthenticationActions.Authentication_TOKEN.FAILED:
                    return { ...state,  Authentication_Status: AuthenticationStatus.Authentication_TOKEN.FAILED, Authentication_Message:action.error}

        case AuthenticationActions.Authentication_TOKEN.NOT_AUTHORIZED:
            return { ...state,  Authentication_Status: AuthenticationStatus.Authentication_TOKEN.NOT_AUTHORIZED, Authentication_Message:action.error}
        case AuthenticationActions.Authentication_TOKEN.AUTHORIZED:
          
          return { ...state,  Authentication_Status: AuthenticationStatus.Authentication_TOKEN.AUTHORIZED, Authentication_Message:action.payload}
          
        
        default:
            return { ...state,
                Authentication_Status: AuthenticationStatus.Authentication_TOKEN.NEW
        }
    }
}
