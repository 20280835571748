//*************************************************************************;
/*
  The system displays a green message when a successful post request is made and 
  red message if there is an error during server transaction
  
  */
//  const {Users} =require("")
module.exports = {
  /*

    To change any message, you need to only change the text in double quotes
  */
  //**************************************************************** */
  //**************************************************************** */

  success_messages: {
    // List of success messages in our system

    //*********************Admin Web Messages*********************
    activePointsCreationSuccessAccessLink:'Points created of date ${date}',
    authorizationSuccess: "Authorized successfully.", //admin has successfully logged into our system

    /* Exchange Rate Screen */
    NewExchangeRate: "You have successfully created new exchange rate.",
    UpdateExchangeRate: "You have successfully updated this exchange rate.",
    DeleteExchangeRate: "You have successfully deleted this exchange rate.",

    /** Business Participants wizards */

    RetailerAlreadyExists: "Customer Already Exists.", //if business name already present
    NewBusinessProfile: "You have successfully created new business profile.",
    UpdateBusinessProfile:
      "You have successfully updated this business profile.",
    NewBusinessContract: "You have successfully saved the business contract.",
    RetailerProfileCreated:
      "Congratulations! Your business profile has been submitted successfully.",

    UpdateBusinessContract:
      "You have successfully updated the business contract.",
    UpdateBusinessStatus:
      "You have successfully updated the status of business.",
    NewBusinessCouponquota:
      "You have successfully saved the business offer quota for year 20XX.", //XX replace dynamically
    UpdateBusinessCouponquota:
      "You have successfully updated the business offer quota for year 20XX.",
    GenerateCouponSuccess: "You have successfully generated {X} new offers", //4th step
    SubmitCouponSuccess: "Offer Generation Request Submitted", 
    SaveAsDraftSuccess: "Offer Saved As Draft", 
    UpdateAsDraftSuccess: "Offer Updated As Draft", 
    UpdateCouponExpirySuccess: "Offer updated successfully",
    GenerateCouponSuccess2:"Offer Generation Successful",
    stripe_link:"Stripe Account linked successfully",
    /** Banner Management and Schedule */
    NewBannerUploadSuccess: "You have successfully uploaded a banner.",
    BannerUpdateSuccess: "You have successfully updated a banner.",
    BannerDeleteSuccess: "Banner deleted successfully.",
    BannerScheduleSuccess: "Banner Scheduled Successfully.",

    /** Upload coupon Image screen */
    CouponImageUploadSuccess: "Offer Image Uploaded Successfully.",
    CouponVideoUploadSuccess: "Offer Video Uploaded Successfully.",
    CouponApprovelSuccess:"Offer Approved Sucessfully",
    CouponDisApprovelSuccess:"Offer Declined Successfully",
    CouponFeedbackSuccess:"Feedback sent successfully",
    /**Help Support Screen */

    RiderResponceSuccess: "Response sent",

    /**User Management Screen */
    PasswordChange: "Password change successfully, user notified via email.",
    AdminProfileCreated: "New Admin user added and Password sent via email.",
    RetailerUserCreated:"New Partner user added and Confirmation email sent.",
    RetailerUserCreatedAdmin:"New Partner Admin user added successfully.",

    EmailChange:'Email Change successfully',

    /**Message Management Screen */
    MessageSend: "Message sent successfully to Customers.",

    /**Generate Promotion Points Screen */
    GeneratePoints1: "Successfully generated new Points.",
    GeneratePoints2: "Successfully generated new Points for users:", //when list of emails provided

    /**Manage Reference Tables */
    ReferenceDataAddSuccess: "Data Added Successfully.",
    ReferenceDataEditSuccess: "Data Updated Successfully.",
    ReferenceDataDeleteSuccess: "Data Deleted Successfully.",

    NewSplashDetails:"Splash Screen Details Added Successfully.",
    //************************** The following are Retailer App Messages ****************

    /*"Redeem Coupons" screnn*/
    RedeemCouponSuccess: "Successfully Redeemed the new offer code.",
    RedeemCustomerCouponSuccess:"Offer redeemed successfully",
        /*Retailer Coupons Inventory screnn*/
    BatchCouponStatusSuccess: "Offer status updated successfully.",

    /*Forget password */
    ResetRetailerPassword:
      "Reset Password successful. Now you can login with your new password.",
      ResetPasswordSendEmail:
      "Email sent successfully. Check your inbox.",

      //Email Chnage of Retailer
      ResetEmailRetailer:'Email Updated Successfully, User informed Via Email ',

    //payment collection and account selection
    PaymentCollectionSuccess: "Invoice paid successfully.",
    AccountAddSuccess: "Payment account added successfully.",

    //****************************The following are Rider Web Messages**************

    purchaseCouponSuccess: "You have purchased this Offer successfully.",
    RiderHelpSupportSuccess: "Your Query Submitted successfully.",
    unclaimCouponSuccess: "Offer Returned Successfully.",
    //********************************Batch Process Messages*******************
    pointsCreationSuccess: "Points Created Successfully.",
    activePointsCreationSuccess: "Points created of date ${date}",
    mobileUserGenerationSuccess: "Customer users created Successfully.",
    /*Invoices Screen  */
    invoiceGenerated: "Invoices generated successfully.",
//* Kevel Messages */
kevel_warning:"Successfully created campaign, but unable to create some of its banners",
kevel_success:"Successfully created new banner campain",

    //*******Generic messages*********
    LogoutSuccessful: "You have successfully logged out of the system.",

    NewSubscriberSuccess: "You have successfully subscribed to NJT Rewards!",
    PartnerJWTDeleteSuccess:"JWT successfully deleted from DB",


    OptOutVerificationCodeSuccess:"Verification Code Sent Successfully to Email.",

    SurveyPointsGivenSucess:"250 Points issued Successfully!",
    SurveyPointsAutoRegisterSuccess:"You have been successfully registered as NJT Rewards Platform member, 250 Points issued Successfully!",

  },

  //************************************************************************************* */
  //************************************************************************************* */

  failure_messages: {
    // List of failure messages in our system

    //******************Admin Web Messages*********************

    /* Login Screen*/
    Adminlogin: "Failed to Login because of Incorrect Credentials ! Please Try Again.",
    authorizationError:
      "Authentication failed! Please refresh your app and Try again.",

    /**Business participants Screen */
    getProfileError:'Failed to Get Profile Data',
    BusinessProfileError: "Failed to create new business profile.",
    BusinessContractError: "Failed to save business contract details.",
    Businessparticipants: "Failed to fetch the business participants details.", //list rendering error
    coupongenerationError: "Unable to generate Offer.",
    quotasDetailsError: "Could not generate offers because it failed to get offer quotas.",
    unusedQuotasError:"Could not generate offers because it failed to get exchange rates.",
    OverrideCouponsGetError: "Failed to get Override Offer Details.",
    CouponsGetError: "Failed to get Offers Details.",
    quotasSaveError: "Unable to save business offer quotas.",
    quotaUpdateError: "Unable to update business offer quotas.",
    quotasListError: "Failed to get list of quotas.",
    CouponQuotaLimit:
      "You cannot add more quotas, as business contract has reached its maximum years Limit.",
    CouponCreateLimit: "Failed to get global offer create limit.",
    faceValuesError: "Failed to get Face Values.",
    contractYearsError: "Failed to get list of contract years.",
    contractDetailsError: "Failed to get Contract Details.",
    updateContractDetailsError: "Failed to update Contract Details.",
    RetailerBusinesses: "Failed to fetch list of Customers.",
    CouponApprovelError:"Failed to approve the offers.",
    /*"List of New Applicants" screen.*/
    BusinessApplicants: "Failed to fetch the business applicants details.",

    /*"View Change Logs" screen.*/
    ChangeLogfilter: "Failed to fetch the data.",
    ChangeLogdownload: "Unable to download the requested data.",

    /* Exchange Rate screen */

    ExchangeRateCreateError: "Failed to create exchange rates.",
    ExchangeRateUpdateError: "Failed to update exchange rates.",
    ExchangeRateDeleteError: "Failed to delete exchange rates.",
    ExchangeRateGetError: "Failed to fetch exchange rates.",

    /* Application  screen */
    StatusGetError: "Failed to get the statuses.",
    LogoutError: "Unexpected Error! Try again later.",
    ApprovedBusinessError:"Failed to get business contract details.",
    /* Generate promotion points screen */
    GeneratePoints: "Could not Generate Points. No tickets found.",
    GeneratePoints_EmailError:
      "Could not Generate Points. No NJT user email found.",

    /* Banners screen */
    DuplicateActiveBanner: "You can’t duplicate an active banner.",
    BannerScheduleError: "Unable to Schedule Banners.",
    getScheduledBannerError: "Unable to get Scheduled Banners.",
    ScheduledWeeksError: "Unable to get Scheduled Weeks.",
    DeleteBanner: "You can’t delete this banner.",

    //invoices
    invoiceGenerationError: "Error in invoice generation.",
    invoiceGetError: "Failed to get List of Invoices.",
    ReferenceDataAddError: "Unable to Add Record.",
    ReferenceDataEditError: "Unable to Update Record.",
    ReferenceDataGetError:
      "Unable to Get Table Fields. You won't be able to add new data.",

    //***************************Retailer App Messages***************

    /* Authentication screen */
    authorizationErrorRetailer: "Incorrect Credentials ! Please Try Again.",
    authenticationErrorRetailer: "User Authentication Failed.",

    authorizationErrorRiderEmail: "Incorrect Email or Password.",
    riderFirstLoginError: "Unable to update first login status.",
    tokenExpiredError:"Link Expired ! Please Try again with new link",
    /* Generate Coupon screen */

    GenerateCouponQuantity: "No. of offers have exceeded the quota.",
    GenerateCouponError: "Unexpected Error! Unable to generate new offers.",

    BatchCouponStatusFailure:"Failed to update offer status.",
    UpdateCouponBatchFailure:"Failed to update offer batch details.",

    /* Redeem coupons screen */
    RedeemCouponInvalidCoupon:
      "Failed to redeem! This offer does not belong to your business.",
    RedeemCouponincorrectcode:
      "Failed to redeem! This offer code does not exist.",
    RedeemedCouponcode: "This offer code has already been redeemed.",
    RedeemCouponerror: "Failed to redeem the offer.",
    RedeemCouponExpireError: "This offer code has expired.",
    RedeemCouponExpiredError: "This offer code has expired.",
    RedeemCouponNotIssueError: "This offer is not issued to any user.",
    RedeemCouponTodayError: "Can’t redeem the offer today.",
    RedeemCouponExpiryError:"can't redeem this offer because it is expired.",
    SignupRetailerError:"Failed to signup! Business Name already exists.",
    EmailNotFoundError: "No user exists for given email.",
    UserNameNotFoundError: "Username not found" ,
    EmailAlreadyExists: "Username already exists.",
    RiderAlreadyExists: "Failed to register! Email already exists.",
    CouponNotVerified:"Offer Not Verified",
    SignupBusinessError: "Error! Business name already exists.",

    /* Notifications screen */
    NotificationsStatusError: "Failed to update Notifications Status.",

    //*********************** */ NJT Rider App Messages****************
    LinkTokenFailure:"Failed to create Link Token",
    /* Home screen and points history */
    pointsGetError: "No points available against your username.",
    purchaseCouponError: "Unable to Purchase Offer.",

    emptyWallet: "Wallet empty! See available offers.",

    //coupons List screen
    emptyCouponsList: "No rewards currently available. Check back soon!",
    emptyCouponSearchError:"No search results match your criteria.",
    authorizationExpiryError:
      "Authentication failed! Please refresh your app and try again.",
    authorizationErrorRider: "Authentication failed! Invalid username.",
    purchaseCouponNotAvailableError: "This offer is no more available.",
    purchaseCouponPointError: "You do not have enough points to purchase.",
    emptyMyCouponsList:
      "Your wallet is currently empty. Go to Rewards to select offers.",

    //Reference Table Management */
    DuplicateIdError:
      "You cannot add a record when an id already exists. You need to edit it or add one with a different id.",
    RiderEmailAlreadyExists: "Unable to subscribe! Email already exists.",
    RiderAlreadyActivated: "Your account is already linked. Please try login with your email.",

    //help support
    RiderHelpSupportError: "Unable to Connect, Try again Later.",
    UnclaimCouponError: "Unable to return offer.",

    couponReturnError:"Offer can't be returned once offer code is revealed",

    //****************DB un-expected Error********************
    ConnectionError: "OOPs! your device has lost internet connection.",
    unExpectedError: "Unexpected error! Try again later.",

    SessionExpired :"Your session has been expired! Please Login Again",
    //******************Batch Process Messages***************
    pointsCreationFailure: "Error in points record creation.",
    mobileUserGenerationError: "Error in Customer users generation.",
    EmailEncryptionError_Empty: "Error: Provide Email to Encrypt.",
    EmailEncryption_EmptyString: "Error: Provided email is Empty String",
    EmailEncryption_UserIdEmpty: "Error: Provided User Id is Empty String",
    EmailEncryption_UndefinedNull: "Error: Provided email is undefined or null",

    EmailDecryption_UndefinedNull: "Decryption Error: The provided userd id is undefined or null",
    EmailDecryption_EmptyString: "Decryption Error: Decrypted String is Empty",

    EmailEncryptionError: "Error: Unexpected error occur, try again later.",

    RefTransitServiceTokenInvalid: "The provided token is invalid.",

    // ******* Stripe Error Messages **********
    StripeErrorMsg: "You can't purchase this offer at this time.",

    // ******* File Upload Error Msg
    csvFileUploadErrorMsg: "Only csv file is allowed.",

    CustomerLandingPageAuthorizationError:"Error: Authorization API key not provided!",

    UpdateRetailerTermsVersionError:"Failed update retailer terms version.",
    PartnerBusinessNameGetError:"Failed to get Partner Business Name",
    SubmitCouponForApprovalGetError:"Failed to get Submit Coupon for Approval from ref_globals",
    RefMonthGetError:"Failed to get ref months.",
    PartnerContractDateGetError:"Failed to get contract term dates.",
    CouponThresholdGetError:"Failed to get Coupon Threshold from ref_globals",
    DisplayCouponTagGetError:"Failed to get display coupon tag from ref_globals",
    AllBatchStatusGetError:"Failed to get all batch status.",
    CouponImagesGetError:"Failed to get offer images.",
    CouponSubTypeGetError:"Failed to get offer subtypes.",
    CouponApprovalCheckListGetError:"Failed to get offer approval checklist.",
    BusinessCategoryGetError:"Failed to get business categories.",
    PaymentAccountDetailGetError:"Failed to get payment account details for the business.",
    UploadCouponImageError:"Failed to upload coupon image.",
    ZapierError:"Failed to send data to Zapier.",
    


    PartnerJWTDeleteError:"Failed to delete partner JWT",
    SaveAsDraftError: "Failed to save Offer as Draft.",

    CustomerOptOutErrorMsg: "You are not currently enrolled in the NJT Rewards Program.",
    OptOutCodeInvalid:"Code is Invalid.",
    OptOutCodeExpired:"Code is Expired.",

    SurveyBonusPointsErrorMsg: "You can recieve bonus points for filling out the survey only once.",
    SurveyBonusPointsUserNotExist: "You are not part of NJT Rewards Program."
  },
  RapidUserRigistration:"Dear %u,\n\n customer user added. Your password is : %p",
    
  Notification: {
    invoiceDescription:
      "Dear %u, NJT Rewards has sent an invoice for the month of %m",
    invoiceTitle: "Invoice for the month of %m",
  },
  //************* Login API services */
  EmailEncryptionError_Unauthorized: "Error: Unauthorized user.",
};

