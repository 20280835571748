import { OfferInWallerStatus, OfferInWallerActions } from './OfferInWallerConstants';

const OfferInWaller_initialState = {
    OfferInWaller_Status: OfferInWallerStatus.OfferInWaller_GET.NEW,
    OfferInWaller_Error: '',
    OfferInWallerDetails: [],
    OffersCount:0
}
export default  function  (state = OfferInWaller_initialState, action) {
    switch (action.type) {
        case OfferInWallerActions.OfferInWaller_GET.LOADING:
            return { ...state, OfferInWaller_Status: OfferInWallerStatus.OfferInWaller_GET.LOADING }
            case OfferInWallerActions.OfferInWaller_GET.NEW:
                return { ...state, OfferInWaller_Status: OfferInWallerStatus.OfferInWaller_GET.NEW, OfferInWallerDetails: [] }
    
        case OfferInWallerActions.OfferInWaller_GET.FAILED:
            return { ...state,  OfferInWaller_Status: OfferInWallerStatus.OfferInWaller_GET.FAILED, OfferInWaller_Error:action.error}
        case OfferInWallerActions.OfferInWaller_GET.SUCCESS:
          
          return { ...state,  OfferInWaller_Status: OfferInWallerStatus.OfferInWaller_GET.SUCCESS, OfferInWallerDetails:action.payload, OffersCount:action.count}
          
        
        default:
            return { ...state,
                
            }
    }
}
