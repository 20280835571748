import React, { Component } from 'react'
import "../HeaderSection/InnerHeader.css"
import { Link, withRouter } from "react-router-dom";

class InnerHeader extends Component {
    constructor(props){
        super(props)
        this.state = {
            isSignIn: false,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
         if(nextProps.location.pathname == "/")
         return { isSignIn: false};
         else
         return { isSignIn: true};
        
     }
    render() {
        return (
            <div className="in-header-wrap">
                <div className="in-header-container">
                    <div className="in-header-left">
                        <h1><Link className="in-header-link" to={this.state.isSignIn ?"/Admin":"/"}>NJT Rewards Platform</Link></h1>
                    </div>
                  
                </div>
            </div>
        )
    }
}

export default  withRouter(InnerHeader)
