import { connect } from "react-redux";
import SendAnswerView from "./SendAnswer_View";
import {SendAnswerServer} from "./server"
import {SendAnswerActions} from './SendAnswerConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        SendAnswer_POST_status:state.SendAnswerReducer.SendAnswer_Status,
        SendAnswer:state.SendAnswerReducer.SendAnswerDetails,
        SendAnswer_Message:state.SendAnswerReducer.SendAnswer_Message
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        sendAnswer: (data) => {

           dispatch( SendAnswerServer.sendAnswer(data) )
        },
        resetReducerState:()=>{
            dispatch({type:SendAnswerActions.SendAnswer_POST.NEW})
        }
    }
};
const SendAnswerContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SendAnswerView)

export default SendAnswerContainer;