import React from "react";
import "./SimpleInput.css";
function SimpleInput(props) {
  const {
    onChange,
    inputValue,
    placeholder,
    name,
    id = 'si_input',
    errorMsg = "Only numeric values are allowed",
    flag = false,
    maxLength = "12",
    containerStyle,
    disabled = false,
    type = "text",
    showLength = false,
    label='Search'
  } = props;
  // console.log('simpleValue:', inputValue)

  return (
    <div className="si_input_box">
      <input
        id={id}
        className="si_input"
        type={type}
        name={name}
        label={label}
        value={inputValue}
        placeholder={placeholder}
        onChange={onChange}
        maxLength={maxLength}
        style={containerStyle}
        disabled={disabled}
      />
      {maxLength && showLength ? (
        <p
          className="ltai_label"
          style={{
            textAlign: "right",
            width: "100px",
            float: "right",
          }}
        >
          {inputValue.length} / {maxLength}
        </p>
      ) : null}
      <p className="si_error_text" style={{ display: flag ? "block" : "none" }}>
        {errorMsg}
      </p>
    </div>
  );
}

export default SimpleInput;
