import { TransactionHistoryStatus, TransactionHistoryActions } from './TransactionHistoryConstants';

const TransactionHistory_initialState = {
    TransactionHistory_Status: TransactionHistoryStatus.TransactionHistory_GET.NEW,
    TransactionHistory_Error: '',
    TransactionHistoryDetails: [],
    TransactionsCount:0
}
export default  function  (state = TransactionHistory_initialState, action) {
    switch (action.type) {
        case TransactionHistoryActions.TransactionHistory_GET.LOADING:
            return { ...state, TransactionHistory_Status: TransactionHistoryStatus.TransactionHistory_GET.LOADING }
            case TransactionHistoryActions.TransactionHistory_GET.NEW:
                return { ...state, TransactionHistory_Status: TransactionHistoryStatus.TransactionHistory_GET.NEW, TransactionHistoryDetails: [] ,TransactionsCount:0}
    
        case TransactionHistoryActions.TransactionHistory_GET.FAILED:
            return { ...state,  TransactionHistory_Status: TransactionHistoryStatus.TransactionHistory_GET.FAILED, TransactionHistory_Error:action.error}
        case TransactionHistoryActions.TransactionHistory_GET.SUCCESS:
          
          return { ...state,  TransactionHistory_Status: TransactionHistoryStatus.TransactionHistory_GET.SUCCESS, TransactionHistoryDetails:action.payload, TransactionsCount:action.count}
          
        
        default:
            return { ...state,
                
            }
    }
}
