import React, { Component, Fragment } from "react";
import { GeneratePromotionalPointStatus } from "./GeneratePromotionalPointConstants";
import GeneratePromotionalPoint from "./GeneratePromotionalPoint";

import Loading from "../Utils/Loading";

export class GeneratePromotionalPointView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      mount: false,
      getTableData: {}
    };
  }

  closeSnackBar = () => {
    this.setState({
      open: false
    });
  };
  componentDidMount() {
  }
  
  getScreen(status) {
    // alert(status)
    switch (status) {
      case GeneratePromotionalPointStatus.GeneratePromotionalPoint_Get.NEW:
        //  alert(JSON.stringify( this.props.GeneratePromotionalPoint))
        return (
          <GeneratePromotionalPoint
          CreatePromotionalPoints={this.props.CreatePromotionalPoints}
          cookies={this.props.cookies}
          />
        );

      case GeneratePromotionalPointStatus.GeneratePromotionalPoint_Get.SUCCESS:
        return (
          <GeneratePromotionalPoint
          CreatePromotionalPoints={this.props.CreatePromotionalPoints}
          cookies={this.props.cookies}
          />
        );
      case GeneratePromotionalPointStatus.GeneratePromotionalPoint_Get.FAILED:
        return (
          <Fragment>
           
            <GeneratePromotionalPoint
              CreatePromotionalPoints={this.props.CreatePromotionalPoints}
              cookies={this.props.cookies}
            />
          </Fragment>
        );

      case GeneratePromotionalPointStatus.GeneratePromotionalPoint_Get.LOADING:
        this.state.open = true;
        return (
          <div style={{ textAlign: "center",display:"flex", height:"65vh", width:"100%", justifyContent:"center",alignItems:"center" }}>
            <Loading/>
          </div>
        );
      default:
        return <div />;
    }
  }
  render() {
    // alert(this.props.history)
    return this.getScreen(this.props.GeneratePromotionalPoint_Get_status);
  }
}

export default GeneratePromotionalPointView
