import { UserUnsedPointsStatus, UserUnsedPointsActions } from './UserUnsedPointsConstants';

const UserUnsedPoints_initialState = {
    UserUnsedPoints_Status: UserUnsedPointsStatus.UserUnsedPoints_GET.NEW,
    UserUnsedPoints_Error: '',
    PointsCount:0
}
export default  function  (state = UserUnsedPoints_initialState, action) {
    switch (action.type) {
        case UserUnsedPointsActions.UserUnsedPoints_GET.LOADING:
            return { ...state, UserUnsedPoints_Status: UserUnsedPointsStatus.UserUnsedPoints_GET.LOADING }
            case UserUnsedPointsActions.UserUnsedPoints_GET.NEW:
                return { ...state, UserUnsedPoints_Status: UserUnsedPointsStatus.UserUnsedPoints_GET.NEW, PointsCount:0 }
    
        case UserUnsedPointsActions.UserUnsedPoints_GET.FAILED:
            return { ...state,  UserUnsedPoints_Status: UserUnsedPointsStatus.UserUnsedPoints_GET.FAILED, UserUnsedPoints_Error:action.error}
        case UserUnsedPointsActions.UserUnsedPoints_GET.SUCCESS:
          
          return { ...state,  UserUnsedPoints_Status: UserUnsedPointsStatus.UserUnsedPoints_GET.SUCCESS, UserUnsedPointsDetails:action.payload, PointsCount:action.PointsCount}
          
        
        default:
            return { ...state,
                
            }
    }
}
