import React, { Component, Fragment } from "react";
import { GetRefTransactionTypesStatus } from "./GetRefTransactionTypesConstants";
import GetRefTransactionTypes from "./GetRefTransactionTypes";
import Snackbar from "@material-ui/core/Snackbar/";
import Loading from "../../Utils/Loading";

export class GetRefTransactionTypesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      mount: false,
      inputValue: ""
    };
    props.getGetRefTransactionTypes();
  }
  closeSnackBar = () => {
    this.setState({
      open: false
    });
  };
  
  static getDerivedStateFromProps(props, state) {
    if (JSON.stringify(props.inputValue) !== JSON.stringify(state.inputValue)) {
   
      if(props.inputValue == "")
      props.resetReducerState()
        return {
          inputValue: props.inputValue
        };
 
    } else
     return null;
  }
  getScreen(status) {
    // alert(status)
    switch (status) {
      case GetRefTransactionTypesStatus.GetRefTransactionTypes_Get.NEW:
        //  alert(JSON.stringify( this.props.GetRefTransactionTypes))
        return (
          <GetRefTransactionTypes
          RefTransactionTypeList={this.props.RefTransactionTypeList}
            inputValue={this.props.inputValue}
            flag={this.props.flag}
            onChange={this.props.onChange}
          />
        );

      case GetRefTransactionTypesStatus.GetRefTransactionTypes_Get.SUCCESS:
        
      // alert(JSON.stringify(this.props.RefTransactionTypeList))

        return (
          <GetRefTransactionTypes
          RefTransactionTypeList={this.props.RefTransactionTypeList}
            inputValue={this.props.inputValue}
            flag={this.props.flag}
            onChange={this.props.onChange}
          />
        );
      case GetRefTransactionTypesStatus.GetRefTransactionTypes_Get.FAILED:
        //  alert(JSON.stringify( this.props.GetRefTransactionTypes))
        return (
          <Fragment>
            <Snackbar
              open={this.state.open}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
              ContentProps={{
                "aria-describedby": "message-id"
              }}
              message={
                <span id="message-id">Unable to get list of applicants</span>
              }
            />
            <GetRefTransactionTypes
              RefTransactionTypeList={this.props.RefTransactionTypeList}
              inputValue={this.props.inputValue}
              flag={this.props.flag}
              onChange={this.props.onChange}
            />
          </Fragment>
        );

      case GetRefTransactionTypesStatus.GetRefTransactionTypes_Get.LOADING:
        this.state.open = true;
        return (
          <div style={{ textAlign: "center" }}>
            <Loading/>
          </div>
        );
      default:
        return <div />;
    }
  }
  render() {
    return this.getScreen(this.props.GetRefTransactionTypes_Get_status);
  }
}

export default GetRefTransactionTypesView
