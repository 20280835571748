import { connect } from "react-redux";
import PointsHistoryView from "./PointsHistory_View";
import {PointsHistoryServer} from "./server"
import {PointsHistoryActions} from './PointsHistoryConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        PointsHistory_GET_status:state.PointsHistoryReducer.PointsHistory_Status,
        PointsHistory:state.PointsHistoryReducer.PointsHistoryDetails,
        PointsHistory_Error:state.PointsHistoryReducer.PointsHistory_Error,
        PointsCount:state.PointsHistoryReducer.PointsCount
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        getPointsHistoryByUserId: (data) => {

           dispatch( PointsHistoryServer.getPointsHistoryByUserId(data) )
        },
    
        resetReducerState:()=>{
            dispatch({type:PointsHistoryActions.PointsHistory_GET.NEW})
        }
    }
};
const PointsHistoryContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PointsHistoryView)

export default PointsHistoryContainer;