import { SendAnswerStatus, SendAnswerActions } from './SendAnswerConstants';

const SendAnswer_initialState = {
    SendAnswer_Status: SendAnswerStatus.SendAnswer_POST.NEW,
    SendAnswer_Message: '',
    SendAnswerDetails: [],
}
export default  function  (state = SendAnswer_initialState, action) {
    switch (action.type) {
        case SendAnswerActions.SendAnswer_POST.LOADING:
            return { ...state, SendAnswer_Status: SendAnswerStatus.SendAnswer_POST.LOADING }
            case SendAnswerActions.SendAnswer_POST.NEW:
                return { ...state, SendAnswer_Status: SendAnswerStatus.SendAnswer_POST.NEW, SendAnswerDetails: [] }
    
        case SendAnswerActions.SendAnswer_POST.FAILED:
            return { ...state,  SendAnswer_Status: SendAnswerStatus.SendAnswer_POST.FAILED, SendAnswer_Message:action.error}
        case SendAnswerActions.SendAnswer_POST.SUCCESS:
          
          return { ...state,  SendAnswer_Status: SendAnswerStatus.SendAnswer_POST.SUCCESS, SendAnswerDetails:action.payload,
            SendAnswer_Message:action.message
        }
          
        
        default:
            return { ...state,
                
            }
    }
}
