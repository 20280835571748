import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import "../UploadBannerCard/UploadBannerCard.css";

const modalRoot = document.getElementById('send_portal')

class Modal extends React.Component {
  render() {
    return ReactDOM.createPortal(
      <div
        style={{
          position: 'fixed',
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
          display: 'grid',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0,0,0,0.3)',
          minHeight: '100vh',
          zIndex:'999999999999'
        }}
        onClick={this.props.onClose}
      >
        <div
          style={{
            padding: 20,
            background: '#fff',
            borderRadius: '2px',
            display: 'inline-block',
            minHeight: '200px',
            margin: '1rem',
            position: 'relative',
            minWidth: '500px',
            boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
            justifySelf: 'center',
          }}
        >
          {this.props.children}
          <div className="main-del-box">
                <div className="del-pop-text question">
                Response Sent!
                </div>
            </div>
            <div className="del-pop-bnt">
                <button id="okayBtn" className="btn-yes">Okay</button>
            </div>
        </div>
      </div>,
      modalRoot,
    )
  }
}


 class SendModal extends Component {

  render() {
    return (
      <div
        style={{
          height: '100%',
        }}
      >
       
          <button id="sendBtn" onClick={this.props.sendAnswer} className="send-btn">
            Send
          </button>
          {this.props.showModal ? (
            <Modal onClose={this.props.handleCloseModal}>
            </Modal>
          ) : null}
       
      </div>
    )
  }
}

export default SendModal
