import React from 'react'
import './LabelInput.css'
function LabelInput(props) {

    const { label, type = "text", inputValue, placeholder, name = "name", onChange, errorMsg = 'errorMsg', flag = false, containerStyle, readOnly = false, maxLength, disabled, inputStyle, inputContainerStyle, id="inputText" } = props
    return (
        <div className="li_container" style={containerStyle}>
            {
                label ? <label htmlFor="" className="li_label">{label}</label>:null
            }            
            <div className="li_input_container" style={inputContainerStyle}>
                <input id={id} className="li_input" style={inputStyle} type={type} placeholder={placeholder} name={name} value={inputValue} onChange={onChange} readOnly={readOnly} maxLength={maxLength} disabled={disabled} />

                <p className="li_error_text" style={{ display: flag ? 'block' : 'none' }}>{errorMsg}</p>
            </div>
        </div>
    )
}

export default LabelInput
