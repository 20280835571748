import React, { Component, Fragment } from "react";
import DropDownInput from '../../Inputs/DropDownInput'
class GetBusRoutes extends Component {
  render() {
    const {
      inputValue,
      flag,
      onChange,
      BusRouteList,
      
    } = this.props;
    return (
      <Fragment >

                                   <DropDownInput
                            data={BusRouteList}
                            type="text"
                            name="Bus Routes"
                            FirstValue="Select Bus Route"
                            id="selectBusRoute"
                            inputValue={inputValue}
                            flag={flag}
                            errorMsg={"Field Required"}
                            onChange={onChange}

                            />
      </Fragment>
    );
  }
}

export default GetBusRoutes;
