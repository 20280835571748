import { RiderQueationsListStatus, RiderQueationsListActions } from './RiderQueationsListConstants';

const RiderQueationsList_initialState = {
    RiderQueationsList_Status: RiderQueationsListStatus.RiderQueationsList_GET.NEW,
    RiderQueationsList_Error: '',
    RiderQueationsListDetails: [],
    QuestionsCount:0
}
export default  function  (state = RiderQueationsList_initialState, action) {
    switch (action.type) {
        case RiderQueationsListActions.RiderQueationsList_GET.LOADING:
            return { ...state, RiderQueationsList_Status: RiderQueationsListStatus.RiderQueationsList_GET.LOADING }
            case RiderQueationsListActions.RiderQueationsList_GET.NEW:
                return { ...state, RiderQueationsList_Status: RiderQueationsListStatus.RiderQueationsList_GET.NEW, RiderQueationsListDetails: [] }
    
        case RiderQueationsListActions.RiderQueationsList_GET.FAILED:
            return { ...state,  RiderQueationsList_Status: RiderQueationsListStatus.RiderQueationsList_GET.FAILED, RiderQueationsList_Error:action.error}
        case RiderQueationsListActions.RiderQueationsList_GET.SUCCESS:
          
          return { ...state,  RiderQueationsList_Status: RiderQueationsListStatus.RiderQueationsList_GET.SUCCESS, RiderQueationsListDetails:action.payload, QuestionsCount:action.QuestionsCount}
          
        
        default:
            return { ...state,
                
            }
    }
}
