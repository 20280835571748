export const AdministrationLogoutActions= {
	
	AdministrationLogout :{
		NEW:"AdministrationLogout_NEW",
		SUCCESS:"AdministrationLogout_SUCCESS",
		FAILED:"AdministrationLogout_FALIURE",
		LOADING:"AdministrationLogout_LOADING",
		UPDATE:"DisableAdministration_Update_SUCCESS",
	},
	
}


export const AdministrationLogoutStatus ={

	AdministrationLogout :{
		NEW:"AdministrationLogout_NEW",
		SUCCESS:"AdministrationLogout_SUCCESS",
		FAILED:"AdministrationLogout_FALIURE",
		LOADING:"AdministrationLogout_LOADING",
	}

}
