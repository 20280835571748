import React, { Component } from "react";
import LabelTextAreaInput from "../../Inputs/LabelTextAreaInput";

import "./UploadBannerCard.css"
import moment from "moment"
import SendAnswerContainer from "../../RiderSupportQuestions/SendAnswer/SendAnswerContainer";
class RiderSupportAnsCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      answerCheck: false,
      isAnswered: props.isAnswered,
      Responce: "",
    };
  }

  setResponce = (e) => {
    this.setState({
      Responce: e.target.value,
    });
  };
  answerHandler = () => {
    this.setState({
      answerCheck: true,
    });
  };

  cancelHandler = () => {
    this.setState({
      Responce: "",
      answerCheck: false
    })
  };
  getUTC_date = (date) => {
    var d = new Date(date);
    d.setDate(d.getUTCDate());
    // alert(d)
    return d;
  };
  render() {
    // alert(this.state.isAnswered)
    const {
      email_address = "Steve Smith",
      submitted_dttm = new Date(),
      question = "Lorem ipsum dolorem",
      response = "",
      response_dttm = new Date(),
      rider_support_request_id = 0,
      // name ="Benjamin Franklin",
      phone = "1234512"
    } = this.props;

    return (
      <div className="rider-card">
        <div className="row">
          <div className="col-2 col-sm-2 col-md-1 col-lg-1">
          <div className="rider-box">
          <div className="rider-card-img">
            <p
              style={{
                borderRadius: 1000,
                background: "#f6853f",
                paddingLeft: 18,
                paddingTop: 18,
                width: 60,
                height: 60,
                fontSize: 18,
                color: "rgba(255,255,255,1)",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              {email_address.slice(0,2)}
            </p>

        
          </div>
          </div>
          </div>
          <div className="col-10 col-sm-10 col-md-11 col-lg-11">
          <div className="rider-box">
          <div className="rider-main-text-b">
            <div className="title-rider name">
              <h5 className="rider-name">{email_address}</h5>
              <div style={{ color: "#707070" }} className="date-question">
                {moment(submitted_dttm).format("MM/DD/YYYY")
                }
              </div>
            </div>
            <div className="box-rider-text">{question}</div>
            {this.state.isAnswered == "Answered" ? (
              <div>
                <div
                  className="title-rider"
                  style={{
                    width: "100%",
                    fontSize: "22px",
                    color: "black",
                    paddingTop: "25px",
                  }}
                >
                  <h5>Answer</h5>
                  <div
                    className="date-question"
                    style={{ paddingTop: 0, color: "#707070" }}
                  >
                    {moment(response_dttm).format("MM/DD/YYYY")
                    }
                  </div>
                </div>
                <div style={{ color: "#707070" }} className="box-rider-text">
                  {response}
                </div>
              </div>
            ) : (
              <div
                className="rider-ans-button"
                style={{
                  display: this.state.answerCheck ? "none" : "block",
                }}
              >
                <button id="answerBtn" className="ans" onClick={this.answerHandler}>
                  Answer
                </button>
              </div>
            )}
            <div
              className="text-box"
              style={{
                display: this.state.answerCheck ? "block" : "none",
              }}
            >
              <div className="box-text">
                <LabelTextAreaInput
                  placeholder="Type answer here"
                  id="typeAnswerHere"
                  inputValue={this.state.Responce}
                  onChange={this.setResponce}
                />
              </div>
              <div className="rider-ans-btn-box">
                <div className="btn-1">
                  <button
                    id="cancelBtn"
                    className="delete-btn"  onClick={this.cancelHandler}
                  >
                    Cancel
                  </button>
                  <SendAnswerContainer
                    rider_support_request_id={rider_support_request_id}
                    Responce={this.state.Responce}
                    email_address = {email_address}
                    getRiderUnAnsweredQuestions = {this.props.getRiderUnAnsweredQuestions}
                    // name ={name}
                    question = {question}
                    phone = {phone}
                  />
                </div>
              </div>
            </div>
          </div>
          </div>
          </div>
        </div>
        <div className="rider-box">
         
        </div>
      </div>
    );
  }
}

export default RiderSupportAnsCard;
