import React, { Component, Fragment } from "react";
import { GetBusRoutesStatus } from "./GetBusRoutesConstants";
import GetBusRoutes from "./GetBusRoutes";
import Snackbar from "@material-ui/core/Snackbar/";
import Loading from "../../Utils/Loading";

export class GetBusRoutesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      mount: false,
      inputValue: ""
    };
    props.getGetBusRoutes();
  }
  closeSnackBar = () => {
    this.setState({
      open: false
    });
  };
  
  static getDerivedStateFromProps(props, state) {
    if (JSON.stringify(props.inputValue) !== JSON.stringify(state.inputValue)) {
   
      if(props.inputValue == "")
      props.resetReducerState()
        return {
          inputValue: props.inputValue
        };
 
    } else
     return null;
  }
  getScreen(status) {
    // alert(status)
    switch (status) {
      case GetBusRoutesStatus.GetBusRoutes_Get.NEW:
        //  alert(JSON.stringify( this.props.GetBusRoutes))
        return (
          <GetBusRoutes
          BusRouteList={this.props.BusRouteList}
            inputValue={this.props.inputValue}
            flag={this.props.flag}
            onChange={this.props.onChange}
          />
        );

      case GetBusRoutesStatus.GetBusRoutes_Get.SUCCESS:
        return (
          <GetBusRoutes
            BusRouteList={this.props.BusRouteList}
            inputValue={this.props.inputValue}
            flag={this.props.flag}
            onChange={this.props.onChange}
          />
        );
      case GetBusRoutesStatus.GetBusRoutes_Get.FAILED:
        //  alert(JSON.stringify( this.props.GetBusRoutes))
        return (
          <Fragment>
            <Snackbar
              open={this.state.open}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
              ContentProps={{
                "aria-describedby": "message-id"
              }}
              message={
                <span id="message-id">Unable to get list of applicants</span>
              }
            />
            <GetBusRoutes
              BusRouteList={this.props.BusRouteList}
              inputValue={this.props.inputValue}
              flag={this.props.flag}
              onChange={this.props.onChange}
            />
          </Fragment>
        );

      case GetBusRoutesStatus.GetBusRoutes_Get.LOADING:
        this.state.open = true;
        return (
          <div style={{ textAlign: "center" }}>
            <Loading/>
          </div>
        );
      default:
        return <div />;
    }
  }
  render() {
    return this.getScreen(this.props.GetBusRoutes_Get_status);
  }
}

export default GetBusRoutesView
