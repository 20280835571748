import React, {  PureComponent } from "react";
import { UserUnsedPointsStatus } from "./UserUnsedPointsConstants";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
// import {Api_Pagination} from 'RefRecordsConstants'
import Loading from "../../Utils/Loading";
// import UserUnsedPoints from "../UserUnsedPoints"
export class UserUnsedPointsView extends PureComponent {
  constructor(props) {
    // alert("points")
    super(props);
    this.state = {
      open: true,
      // activePage:props.activePage
    };
    // if(props.user_id !=="" && props.UserUnsedPoints_GET_status !==UserUnsedPointsStatus.UserUnsedPoints_GET.LOADING){
    //   // alert(nextProps.user_id)
    //   let data={
    //     user_id:props.user_id,
    //     offset:0
    //   }
    //   props.getUserUnsedPointsByUserId(data)
    //   props.clear_user_id()
    // }
    // this.getRiderUnAnsweredQuestions()
  }
componentWillUnmount(){
  this.props.resetReducerState()
}
  // getRiderUnAnsweredQuestions = ()=>{

  //   const data = {
  //     offset : 0,
  //     submitted_dttm :this.props.submitted_dttm,
  //     props:this.props
  //   }

  //   if(this.props.isAnswered == "Answered"){
    
  //     this.props.getRiderAnsweredQuestions(data)
  //   }
  //   else{
  //     this.props.getRiderUnAnsweredQuestions(data)
  //   }
  // }
  static getDerivedStateFromProps(nextProps, prevState){
    // if(nextProps.UserUnsedPoints_GET_status ===UserUnsedPointsStatus.UserUnsedPoints_GET.SUCCESS ||
    //   nextProps.UserUnsedPoints_GET_status ===UserUnsedPointsStatus.UserUnsedPoints_GET.FAILED 
    //   ){
    //   nextProps.setLoading(false)
    // }
    // alert(nextProps.newEmail)
    if(nextProps.user_search_flag && nextProps.UserUnsedPoints_GET_status !==UserUnsedPointsStatus.UserUnsedPoints_GET.LOADING){
      // alert(nextProps.user_id)
      let data={
        user_id:nextProps.user_id,
        // offset: (nextProps.activePage - 1) * Api_Pagination.UserUnsedPointsLimit
      }
      // alert(data.offset)
      nextProps.getUserUnsedPointsByUserId(data)
      nextProps.clear_user_search_flag()
      // nextProps.setLoading(true)
    }
    
    //prevState.activePage !== nextProps.activePage
  //   if(prevState.submitted_dttm !== nextProps.submitted_dttm || prevState.isAnswered !== nextProps.isAnswered || prevState.activePage !== nextProps.activePage){
  //     const data = {
  //       offset : (nextProps.activePage - 1) * 10,
  //       submitted_dttm :nextProps.submitted_dttm ,
  //       props:nextProps
  //     }
  //     if(nextProps.isAnswered == "Answered"){
    
  //       nextProps.getRiderAnsweredQuestions(data)
  //     }
  //     else{
  //       nextProps.getRiderUnAnsweredQuestions(data)
  //     }
  //     return{
  //       submitted_dttm:nextProps.submitted_dttm,
  //       isAnswered:nextProps.isAnswered,
  //       activePage:nextProps.activePage
  //     }
  //  }
   
   return null;
 }
  getScreen(status) {
    switch (status) {
      case UserUnsedPointsStatus.UserUnsedPoints_GET.NEW:
       
        return (
          this.props.PointsCount
        );

      case UserUnsedPointsStatus.UserUnsedPoints_GET.SUCCESS:

      // alert(JSON.stringify(this.props.PointsCount)) 
      // console.log("this.props.UserUnsedPoints:", this.props.UserUnsedPoints)
        return (
          this.props.PointsCount
          // <UserUnsedPoints  data={this.props.UserUnsedPoints}  setNoOfPages={this.props.setNoOfPages} PointsCount={this.props.PointsCount}/>
          // this.props.UserUnsedPoints.length > 0 ?
          // this.props.UserUnsedPoints.map((item)=>{
          //   return(

          //     <RiderSupportAnsCard key ={item.rider_support_request_id}
          //     email_address = {item.email_address}
          //     submitted_dttm = {this.state.submitted_dttm}
          //     question = {item.question}
          //     response = {item.response}
          //     response_dttm = {item.response_dttm}
          //     isAnswered = {this.state.isAnswered}
          //     rider_support_request_id = {item.rider_support_request_id}
          //     getRiderUnAnsweredQuestions = {this.getRiderUnAnsweredQuestions}
          //     phone = {item.phone}
          //     />
          
          //   )
          // }):
          // this.state.isAnswered == "Answered"?
          // < QuestionsEmptyScreen message = {"No Answered Questions yet"} />
          // :< QuestionsEmptyScreen message = {"No Unanswered Questions yet"} />
          
        );
        break;
      case UserUnsedPointsStatus.UserUnsedPoints_GET.FAILED:
        
        toast.error(this.props.UserUnsedPoints_Error)
        return (
              0
           );

      case UserUnsedPointsStatus.UserUnsedPoints_GET.LOADING:
     
        return (
          <Loading />
        );
        break;
      default:
        return <div />;
    }
  }
  render() {
    return this.getScreen(this.props.UserUnsedPoints_GET_status);
  }
}

export default UserUnsedPointsView;
